.loadblock {
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .loadIcon {
        font-size: 35px;
        color: rgba(0, 0, 0, 0.18);
    }
}
