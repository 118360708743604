.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .buttonSidebarEvents {
        position: fixed;
        bottom: 20px;
        right: 20px;
        cursor: pointer;
        z-index: 100000;
    }

    .left {
        min-width: 270px;
        max-width: 270px;
        height: 100vh;
        background-color: #ededed;
        overflow-y: auto;
        position: sticky;
        top: 0;
        overflow-y: auto;
        opacity: 1;

        transition: .2s all;

        .content {
            width: 100%;
            box-sizing: border-box;
            padding: 10px;

            h1 {
                margin: 10px 0;
                font-size: 13px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .listEvents {
                width :100%;

                .eventInfo {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 10px 7px;
                    border-radius: 4px;
                    background-color: #ffffff;
                    margin-bottom: 10px;
                    cursor: pointer;
                    box-shadow: 0 0 10px rgba(0,0,0,0);
                    opacity: .46;
                    transition: .2s all;

                    &:hover {
                        box-shadow: 0 0 10px rgba(0,0,0,0.15);
                        opacity: 1;
                        transition: .2s all;
                    }

                    p {
                        margin: 0;
                        color: #6f6f6f;
                        line-height: 15px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .leftHide {
        min-width: 0px;
        max-width: 0px;
        left: -270px;
        opacity: 0;

        transition: .22s all;
    }

    .right {
        width: 100%;
    }

    .rightDefault {
        width: 75vw;
        margin: 0 auto;

        @media screen and (max-width: 1920px) {
            width: 97vw;
        }
    }
}