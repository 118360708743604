.formBlockVT {
    width: 100%;

    .loadBlockVT {
        width: 100%;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .vtList {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;

        .vtItem {
            width: 100%;
            box-sizing: border-box;
            padding: 15px;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
            cursor: pointer;

            transition: 0.2s all;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);

                transition: 0.2s all;
            }
        }

        .vtItemBind {
            background-color: #9aa5d1;
            color: #fff;
        }
    }
}
