@font-face {
  font-family: 'Gilroy-Black';
  src: url(./fonts/Gilroy-Black.ttf);
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: url(./fonts/Gilroy-Bold.ttf);
}
@font-face {
  font-family: 'Gilroy-Extrabold';
  src: url(./fonts/Gilroy-Extrabold.ttf);
}
@font-face {
  font-family: 'Gilroy-Heavy';
  src: url(./fonts/Gilroy-Heavy.ttf);
}
@font-face {
  font-family: 'Gilroy-Light';
  src: url(./fonts/Gilroy-Light.ttf);
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: url(./fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: 'Gilroy-Regular';
  src: url(./fonts/Gilroy-Regular.ttf);
}
@font-face {
  font-family: 'Gilroy-Thin';
  src: url(./fonts/Gilroy-Thin.ttf);
}