
.contentListGames {
	width:100%;
	height:auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	a {
		width: 50%;
		height: 240px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		border-radius: 10px;
		overflow: hidden;

		h5 {
			position: relative;
			z-index: 10;
			text-decoration: none;
			color: #fff;
			font-size: 20px;
		}

		.gameCardOverlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background: rgba(0,0,0,0.8);
		}
	}
}

.listPlayers {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;

	.itemPlayer {
		width: 49%;
		box-sizing: border-box;
		position: relative;
		padding: 10px;
		margin-bottom: 10px;
		background: #fff;
		box-shadow: 0 0 1px rgba(0,0,0,0.35);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		transition: .2s all;

		&:hover {
			background: #efefef;
			box-shadow: 0 0 15px rgba(0,0,0,0.2);

			transition: .2s all;

			input {
				transform: scale(1);
				transition: .1s all;
			}
		}

		.banPlayer {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1000;
			background: rgba(0,0,0, .82);
			border-radius: 7px;
			display: flex;
			justify-content: center;
			align-items: center;

			h5 {
				font-size: 25px;
				text-transform: uppercase;
				margin: 0;
				color: #d3d5d7;
				font-weight: normal;
				text-shadow: 4px 4px 5px rgba(0,0,0,0.7);
			}
		}

		input {
			padding: 2px 10px;
			cursor: pointer;
			background: #fff;
			border-radius: 5px;
			color: #333;
			right: 10px;
			border: none;
			outline: none;
			position: absolute;
			bottom: 5px;
			font-size: 13px;
			transform: scale(0);

			transition: .2s all;

		}

		h6 {
			margin: 11px 0 0 0;
			color: #9b9b9b;
		}

		.itemPlayerContent {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 10px;
			border-bottom: 1px solid rgba(0,0,0,0.2);	
		}

		.itemPlayerLeft {
			display: flex;
			flex-direction: row;
			align-items: center;

			.phoneAndEmailPlayer {
				display: flex;
				flex-direction: column;

				small {
					color: rgba(0,0,0,0.4);
				}
			}
		}

		span {
			display: flex;
		    width: 35px;
		    height: 35px;
		    background: #333;
		    border-radius: 50%;
		    margin-right: 20px;
		    color: #fff;
		    font-size: 11px;
		    justify-content: center;
		    align-items: center;
		}

		a {
		    display: flex;
		    padding: 5px;
		    box-sizing: border-box;
		    background: #4079c6;
		    color: #fff;
		    border-radius: 7px;
		    font-weight: bold;
		    letter-spacing: 1px;
		    font-size: 15px;
    		padding: 7px 10px;
    		margin-top: 3px;
    		width: fit-content;
		}

		p {
			margin: 0;
		}
	}
}