.console {
    width: 100vw;
    height: 40vh;
    position: fixed;
    top: -50vh;
    left: 0;
    background-color: #444;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 99999999999999;
    transition: .2s all;

    input {
        border: none;
        width: 100vw;
        background-color: #444444;
        border-radius: 0px;
        padding: 20px;
        box-sizing: border-box;
        outline: none;
        color: #eee;
        font-size: 17px
    }
}

.consoleAnable {
    top: 0;

    transition: .2s all;
}