.mainModal {
	width: 100%;
	height: 100vh;
	z-index: 100000;
	background: rgba(0,0,0,0.7);
	top: 0;
	left: 0;
	position: fixed;
	opacity: 0;
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: .1s all;

	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.content {
		width: 70vw;
		height: 80vh;
		background: #fff;
		box-sizing: border-box;
		padding: 10px;
		border-radius: 5px;
		position: relative;
		z-index: 100;
		overflow-y: auto;

		small {
			display: inline;
		    padding: 3px 8px;
		    border-radius: 5px;
		    background: orange;
		    color: #333;
		    font-size: 12px;
		}
	}
}
.mainModalOpen {
	opacity: 1;
	visibility: visible;
	transition: .1s all;
}

.catsMarginLeft {
	margin-left: 10px;
	cursor: pointer;
}

.catsList {
	width:100%;

	div {
		padding:11px 0;
		padding-left:10px;
		width:100%;
		box-sizing:border-box;
		cursor:pointer;
		display:flex;
		flex-direction:column;
		align-items: flex-start;
		background:rgba(0,0,0,0);
		border:1px solid rgba(255,255,255,0);
		border-radius:7px;
		transition:.1s all;

		p {
			margin:0;

			b {
				border:1px solid #333;
				padding:1px 2px;
			}
		}

		&:hover {
			background:rgba(0,0,0,0.025);
			border:1px solid rgba(255,255,255,0.88);
			transition:.1s all;
		}
	}
}