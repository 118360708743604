.spinLoader {
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    color: #d1d1d1;
}

.listBuilders {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    .itemBuilder {
        background-color: #fff;
        border-radius: 7px;
        height: 170px;
        box-sizing: border-box;
        padding: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0 0 33px rgba(0, 0, 0, 0);
        position: relative;
        overflow: hidden;
        border: 1px solid #efefef;

        transition: 0.3s all;

        &:hover {
            box-shadow: 0 0 33px rgba(0, 0, 0, 0.16);

            transition: 0.3s all;
        }

        .itemBuilderLogo {
            width: 100px;
            height: 100px;
        }

        h4 {
            text-align: center;
            margin-top: 3px;
        }

        .buttons {
            display: flex;
            flex-direction: row;
            padding: 10px;
            z-index: 11;
            position: absolute;
            bottom: -100%;
            opacity: 0;
            right: 2%;

            span {
                display: flex;
                width: 35px;
                height: 35px;
                justify-content: center;
                align-items: center;
                color: #fff;
                background-color: #4079c6;
                border-radius: 4px;
                margin-left: 5px;
            }

            transition: 0.3s all;
        }

        &:hover .buttons {
            bottom: -2%;
            opacity: 1;

            transition: 0.3s all;
        }
    }
}

.search {
    width: 100%;
    margin: 15px 0;
}

.userInfo {
    width: 100%;
    margin: 15px 0;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;

    h6 {
        margin: 0px;
    }

    hr {
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.3);
        margin: 11px 0;
        border: none;
    }
}

.paginationBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0;
}

.userInfoForm {
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;

    .userInfoFormPWD {
        width: 96%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .userInfoFormPWDEye {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 36px;
            z-index: 111;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input {
            padding-right: 46px;
        }
    }
}

.buttonSave {
    margin: 5px 0 15px 0;
}

.seoBlock {
    width: 100%;
    margin-bottom: 15px;
    float: left;

    input {
        margin-bottom: 10px;
    }
}

.form {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    background-color: #ffffff;
    margin: 10px 0;
    border-radius: 4px;

    input {
        margin-bottom: 1px;
    }
}

.formBlock {
    width: 100%;

    input {
        margin-bottom: 10px;
    }
}

.uploadFileBlock {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
}

.formWebhook {
    width: 100%;
    display: grid;
    grid-gap: 5px;

    .buttonWebhook {
        max-width: 200px;
    }
}

.formSectionGroup {
    width: 100%;
    padding: 10px 10px 5px 10px;
    border: 1px dashed #aaaaaa;
    box-sizing: border-box;
    margin: 10px 0;
    border-radius: 7px;

    .listPhoneItem {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

}
