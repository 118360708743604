.loadBlock {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loadIcon {
        font-size: 74px;
    }
}

.listOptionPrices {
    width: 100%;
    display: flex;
    flex-direction: column;

    .itemOption {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #fff;
        padding: 10px;
        box-sizing: border-box;
        margin: 7px 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);

        p {
            width: auto;
            border: none;
            color: #292929;
            font-weight: bold;
        }

        input {
            text-align: left;
            background: #f0f0f0;
            outline: none;
            height: 40px;
            margin-bottom: 10px;
        }
    }
}

.blockSeoRealEstate {
    padding: 10px;
    margin: 15px 0;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 0 10px #d1d1d1;

    h4 {
        margin: 0;
    }

}

.inputFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}

.pageForm {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    display: block;

    .steps {
        width: 100%;
        margin: 20px 0;
    }

    .stepsBlock {
        width: 100%;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px #b9b9b9;
        margin: 15px 0;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        margin: 0;

        .button {
            width: 350px;
            height: 180px;
            cursor: pointer;
            margin-right: 15px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 25px;
            background: #1890ff;
            color: #fff;
            text-shadow: 2px 6px 7px rgba(0, 0, 0, 0.34);
        }
    }
}

.formItemStyle {
    margin: 0;
    width: 100%;
}

.formTemplateItemStyle {
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
}

.groupInputs {
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 7px;
    background-color: #eae5f7;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.13);
    margin: 10px 0;
}

.blockUploadImages {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    .uploadImage {
        width: 100%;
        height: 100%;

        p {
            font-size: 11px;
            color: #9f9f9f;
        }

        img {
            max-width: 100%;
            max-height: 200px;
        }

    }
}

.contentPopoverFile {
    width: 100%;

    p {
        margin: 0;
    }
}

.groupformInputs {
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px 5px 2px;
    background-color: #ebebeb;
    margin: 10px 0;
}

.buttonsUploadBlock {
    width: 100%;
    display: flex;
    margin: 15px 0;
    flex-direction: row;
    grid-gap: 10px;

    .buttonUpload {
        width: auto;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        position: relative;
        flex-direction: column;

        img {
            width: 100%;
            max-width: 300px;
            height: auto;
            margin: 20px auto 5px;
            display: block;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        }

        .blockSizesFile {
            width: 100%;
            background: #e9e9e9;
            margin-top: 7px;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 5px;

            h5:nth-child(1) {
                margin-bottom: 3px;
            }

            h5 {
                margin: 0;
            }
        }

        .buttonFile {
            margin: 0;
            position: relative;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 99;
            }
        }
    }
}

.blockRealEstate {
    width: 100%;

    .groupInputs {
        width: 100%;
        background-color: #eae5f7;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.37);
        box-sizing: border-box;
        padding: 10px 5px;
        border-radius: 5px;
        margin: 0 0 15px 0;
    }

    .blockRealEstateHead {
        width: 100%;

        small {
            margin: 5px 0 10px 0;
            display: block;
        }
    }

    input {
    }

    .inputSectionForm {
        width: 100%;
        margin-bottom: 10px;

        .formSelect {
            width: 100%;
        }

        .listVT {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 10px;
            margin: 15px 0 0 0;

            .item_vt {
                width: 100%;
                padding: 15px 15px;
                border-radius: 7px;
                cursor: pointer;
                border: 1px solid rgba(0, 0, 0, 0.3);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    .otherSettingSection {
        width: 100%;
        background: #fff;
        margin-bottom: 10px;
        padding: 0 10px;

        button {
            position: relative;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 111;
                opacity: 0;
                cursor: pointer;
            }
        }

        .inputSectionFormShadow {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
        }

        .uploadButtonBlock {
            width: 100%;
            height: 55px;
            cursor: pointer;
            background: #4079c6;
            position: relative;
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            .uploadButtonBlockIconLoad {
                color: #fff;
                margin-left: 10px;
            }

            small {
                text-transform: uppercase;
                font-size: 13px;
                color: #fff;
                margin: 0;
            }

            input {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 100;
            }
        }

        h4 {
            margin: 0;
        }

        .otherSettingSectionHead {
            width: 100%;
            display: flex;
            cursor: pointer;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
        }
    }
}

.selectInput {
    width: 100%;
}

.listImagesBlock {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 10px;

    .listImageBlock {
        width: 100%;
        margin: 0;
        height: 180px;
        border-radius: 10px;
        position: relative;

        .imageSizeBlock {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.42);
            z-index: 1000;
            box-sizing: border-box;
            padding: 10px;

            h5 {
                margin: 0;
                color: #fff;
            }
        }
    }
}

.datePickBlock {
    width: 100%;
    padding: 10px;
    border-radius: 5px;

    .datePickInput {
        width: 100%;
    }

    b {
        margin: 10px 0 0 0;
        display: block;
    }
}

.syncBlock {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 15px 10px;
    box-sizing: border-box;
    border: 1px solid #e4e4e4;

    h4 {
        margin: 0;
        color: rgba(0, 0, 0, 0.5);
    }

    .syncBlockHead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 0;
        cursor: pointer;
    }
}

.sectionFormFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .checkBox {
        width: 135px;
        position: relative;
        left: 10px;

        span {
            color: rgba(0, 0, 0, 0.55);
            font-weight: normal;
        }
    }
}

.contentPopover {
    p {
        margin: 0;
    }
}

.controlBlockImages {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;

    span {
        position: relative;
        background: #4079c6;
        margin: 0 4px;
        top: 0;
        right: 0;
    }

    span.getFirstPhoto {
        background: #fd9326;
    }
}

.Highlighter {
    background-color: #4079c6;
    color: #fff;
    border-radius: 5px;
}

.filter {
    width: 100%;
    grid-gap: 5px;
    background: #fff;
    padding: 15px 0;

    .form {
        width: 100%;

        .formItems {
            width: 100%;
            display: grid;
            grid-template-columns: 3fr 3fr 3fr 3fr 1fr;
            gap: 10px;
    
            .formItem {
                width: 100%;
    
                .input {
                    width: 100%;
                }
            }
        }
    }

    .select {
        width: 100%;
    }

    .searchInput {
        width: 100%;
    }
}

.blockNotFound {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
        margin: 0px;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: normal;
    }
}

.moreBlock {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.tableRow {
    background-color: #f1f1f1;
    transition: .2s all;

    &:hover {
        background-color: #fffbed;
        transition: .2s all;   
    }
}

.pageContent {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: 0;

    .pagination {
        width: 100%;
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .itemObject {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #f3f5f8;
        margin: 5px 0;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 10px;
        border: 1px solid #e5e5e5;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.02);

        transition: 0.2s all;

        &:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);

            transition: 0.2s all;
        }

        .itemObjectHeader {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        p {
            font-weight: normal;
            margin: 0 0 10px 0;
            color: #000;
            font-size: 17.5px;
        }

        .childListRealestate {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;

            .photoRealestate {
                width: 100%;
                height: 96px;
                margin-bottom: 10px;
            }

            h6 {
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    .pageHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h1 {
            margin: 10px 0px;
        }

        span {
        }

        a {
            color: #fff;
        }
    }

    .pageContent {
        width: 100%;
    }
}

.loadingblock {
    width: 100%;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonSaveSortNumber {
    margin: 20px 0;
}

.blockRealestateList {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;

    .selectStyle {
        width: 100%;
    }

    .realestateList {
    }
}

.listDocuments {
    width: 100%;

    .itemDocument {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px;
        border-radius: 4px;
        box-sizing: border-box;
        margin: 3px 0;
        border: 1px solid #cecece;
    }

    h6 {
        margin: 15px 0;
    }
}

.uploadFileButton {
    position: relative;

    span {
        width: 100%;
        height: 100%;
        display: block;

        input {
            width: 100%;
            height: 100%;
            z-index: 100;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.listRealestate {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
}

.loadBlock {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    color: rgba(0, 0, 0, 0.17);
}

.itemRealestate {
    width: 100%;
    position: relative;
    cursor: pointer;

    .iconGreenMainRealestate {
        opacity: 0;
        visibility: hidden;
        display: none;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;
        border-radius: 5px;
        opacity: 0;
        border: 1px solid rgba(0, 0, 0, 0);

        transition: 0.3s all;
    }

    &:hover:after {
        content: '';
        position: absolute;
        width: 106%;
        height: 110%;
        top: -5%;
        left: -3%;
        border-radius: 5px;
        opacity: 1;
        border: 1px solid #0a2463;

        transition: 0.3s all;
    }
}

.itemMainRealestate {
    width: 100%;

    .iconGreenMainRealestate {
        opacity: 1;
        visibility: visible;
        width: 25px;
        height: 25px;
        background-color: #4da71f;
        border-radius: 50%;
        z-index: 101;
        top: 5px;
        right: 5px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    &:after {
        content: '';
        position: absolute;
        width: 103%;
        height: 103%;
        top: -1.5%;
        left: -1.5%;
        border-radius: 5px;
        opacity: 1;
        border: 2.2px solid rgba(64, 121, 198, 0);
        box-shadow: 0 3px 11px #4079c6;

        transition: 0.3s all;
    }

    &:hover:after {
        content: '';
        position: absolute;
        width: 103%;
        height: 103%;
        top: -1.5%;
        left: -1.5%;
        border-radius: 5px;
        opacity: 1;
        border: 2.2px solid rgba(64, 121, 198, 0);
        box-shadow: 0 6px 18px #4079c6;

        transition: 0.3s all;
    }
}

.descriptionHead {
    position: sticky;
    top: 64px;
    z-index: 500;
}

.objectsContent {
    width: 100%;
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
}

.blockTemplates {
    width: 100%;

    .blockTemplatesHeader {
        width: 100%;
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .blockTemplatesList {
        width: 100%;
        margin: 10px 0;

        .blockTemplateItem {
            width: 100%;
            margin: 18px 0;
            box-shadow: rgba(115, 82, 199, 0.28) 0 0 20px;
            padding: 10px;
            border-radius: 7px;
            height: auto;

            transition: 0.3s all;

            .blockTemplateItemTop {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                grid-gap: 15px;

                .infoBlock {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 4fr 4fr 4fr 4fr 4fr 1fr;
                    grid-gap: 15px;

                    .info {
                        margin-right: 20px;
                        min-width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        p {
                            margin: 0;
                        }

                        span {
                            color: rgba(0, 0, 0, 0.3);
                        }
                    }

                    .infoButtons {
                        margin-right: 0;
                        min-width: 85px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .photo {
                    width: 50px;
                    min-width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .blockTemplateItemBottom {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                grid-gap: 15px;
                height: 0;
                transition: 0.3s all;
                overflow: hidden;

                .infoBlock {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 4fr 4fr 4fr 4fr 4fr 1fr;
                    grid-gap: 15px;

                    .info {
                        margin-right: 20px;
                        min-width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        p {
                            margin: 0;
                        }

                        span {
                            color: rgba(0, 0, 0, 0.3);
                        }
                    }
                }

                .photo {
                    width: 50px;
                    min-width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .blockTemplateItemBottomOpened {
                margin-top: 20px;
                height: fit-content;
                padding-top: 10px;
                border-top: 1px solid rgba(0, 0, 0, 0.19);

                transition: 0.47s all;
            }
        }

        .deleted {
            height: 0;
            opacity: 0;
            visibility: 0;

            transition: 0.3s all;
        }
    }
}

.hide {
    visibility: hidden;
    opacity: 0;
}

.filterObjects {
    width: 100%;
    margin: 15px 0;
    position: sticky;
    top: 60px;
    z-index: 300;
}
