.spinLoader {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 109px;
    color: #d1d1d1;
}

.item {
    width: 100%;
    margin: 10px 0 0 0;
}

.page {
    width: 100%;
    background-color: #fff;
    display: block;
    box-sizing: border-box;
    padding: 10px 0;
    margin: 0;
}

.list {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 10px;
        background-color: #f1f1f1;
        border-radius: 5px;
        margin: 4px 0;

        .itemContent {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .buttons {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .itemSub {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 5px 0 5px 5px;
    }
}

.itemCity {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 15px 10px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin: 5px 0;
    justify-content: space-between;
    align-items: center;

    h4 {
        margin: 0;
        font-weight: normal;
    }

    .itemCityRight {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;

        .itemButton {
            cursor: pointer;
        }
    }
}

.optionBlock {
    width: 100%;
    margin: 15px 0;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;

    input {
        margin: 5px 0;
    }
}
