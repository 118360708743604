.buttonBuild {
    position: fixed;
    z-index: 999999;
    width: 180px;
    height: 35px;
    background: #636eeb;
    color: #fff;
    border-radius: 7px;
    bottom: 20px;
    left: 20px;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0px;
    font-weight: normal;
    text-shadow: 1px 1px 7px rgba(0, 0, 0, 0);
    opacity: 0.7;
    transition: 0.2s all;

    &:hover {
        text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.55);
        font-weight: bold;
        height: 45px;
        letter-spacing: 1.7px;
        opacity: 1;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.48);
        transition: 0.2s all;
    }
}

.errorsNotify {
    position: fixed;
    left: 0;
    top: 10px;
    z-index: 1000000;
    height: auto;
    max-height: 97vh;
    overflow-y: auto;
    max-width: 280px;
    display: flex;
    padding: 0 15px;
    flex-direction: column;

    transition: 0.2s all;

    .audioElement {
        opacity: 0;
        visibility: hidden;
    }

    b {
        cursor: pointer;
        padding: 10px 0;
    }

    .errorNotify {
        width: 100%;
        min-width: 240px;
        padding: 15px 10px;
        margin-bottom: 7px;
        box-sizing: border-box;
        background: #c6405a;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            color: #fff;
            padding: 0 15px 0 0;
        }

        .errorNotifyIcon {
            color: #fff;
        }
    }
}

.errorsNotifyHide {
    height: 42px;
    overflow: hidden;

    transition: 0.2s all;
}

.menuMain {
    width: 100%;
    position: sticky;
    top: 67px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 116px);
}
