.flexRowForm {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.listLogsControll {
    width: 100%;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    .ItemLog {
        width: 100%;
        height: 170px;
        display: flex;
        box-sizing: border-box;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: #ffffff;
        box-shadow: 0 0 20px #dfdfdf;

        h4 {
            text-transform: uppercase;
            color: #333;
            font-weight: normal;
            font-size: 12px;
            letter-spacing: 2px;
        }
    }
}

.listConnections {
    width: 100%;
    display: grid;
    grid-gap: 10px;
    flex-wrap: wrap;
    flex-direction: row;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 20px;

    .itemConnection {
        width: 100%;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 0 10px #e7e7e7;

        h6 {
            word-break: break-all;
        }
    }
}

.buttonRealestate {
    margin-left: 15px;
}

.pagination {
    width: 100%;
    box-sizing: border-box;
    padding: 7px 0;
}

.list_big_controll {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 70px;

    .list_big_controll_tabs {
        width: 100%;
    }

    .list_big_controll_item {
        width: 33%;
        height: 55px;
        cursor: pointer;
        background: #4179c6;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 13px;
        border-radius: 7px;

        h6 {
            color: #fff;
        }

        transition: 0.3s all;
    }

    .list_big_controll_item_selected {
        background: #46566d;

        h6 {
            color: #fff;
            margin: 0;
            font-weight: bold;
            font-size: 16px;
        }

        transition: 0.3s all;
    }
}

.loadingBlock {
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 35px;
        color: rgba(0, 0, 0, 0.27);
    }
}

.log_list_apart_complex {
    width: 100%;
    display: flex;
    flex-direction: column;

    .formBlock {
        width: 100%;
        background-color: rgba(63, 69, 139, 0.1);
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
    }

    .listFlatsBlock {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;

        .item_apart_complex {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            margin: 1px 0;
            background: #fffffe;
            border-radius: 5px;
            border: 1px solid #ededed;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            transition: 0.2s all;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

                transition: 0.2s all;
            }

            p {
                margin: 0;
            }
        }
    }

    .itemsRelaestateBlock {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;

        .item_apart_complex {
            padding: 10px;
            width: 100%;
            background: #fff;
            cursor: pointer;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            border-radius: 5px;

            transition: 0.2s all;

            &:hover {
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

                transition: 0.2s all;
            }

            p {
                margin: 0;
            }
        }
    }

    .log_list_apart_complex_input {
        width: 100%;
        margin: 10px 0;
        box-sizing: border-box;
    }
}

.log_count_block {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.list_big_controll_head {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    input {
        width: 200px;
        height: 47px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        border: none;
        outline: none;
        background: #4079c6;
        color: #fff;
        margin: 15px 0;
    }
}

.input_search {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
        width: auto;
    }

    .input_search_checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        input {
            width: 30px;
            height: 30px;
            margin-right: 5px;
            margin-left: 10px;
        }

        p {
            margin: 0;
            font-size: 8px;
        }
    }
}

.params_shops {
    width: 100%;

    select {
        width: 100%;
        height: 35px;
        border: 1px solid #19628c;
        border-radius: 4px;
    }

    .inputs {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        input {
            width: 100%;
            border: 1px solid #19628c;
            border-radius: 4px;
            height: 35px;
            padding: 0 10px;

            &:nth-child(2) {
                margin: 0 15px;
            }

            &:nth-child(3) {
                background: #1977f3;
                border: none;
                color: #fff;
                outline: none;
            }
        }
    }
}

.selectRealEstateBlock {
    width: 100%;
    margin: 10px 0;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 7px;

    .selectInput {
        width: 100%;
    }

    .selectRealEstateBlockInputs {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
    }
}

.contentBlock {
    width: 100%;
    margin: 0 0 20px 0;

    .defaultBlock {
        width: 100%;
        min-height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;

        h4 {
            margin: 0;
            font-weight: normal;
        }

        .defaultBlockLoadIcon {
            font-size: 45px;
            color: rgba(0, 0, 0, 0.3);
        }
    }

    .parentItemsBlock {
        width: 100%;
        display: flex;
        flex-direction: column;

        .buttonsRight {
            width: auto;
            display: flex;
            flex-direction: row;
        }

        .parentItemsHeadBlock {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: sticky;
            top: 65px;
            z-index: 999;
            background: #fff;
            box-sizing: border-box;
            padding: 10px;
            margin: 0 0 10px 0;
            box-shadow: 0 0 15px #cbcbcb;
            border-radius: 5px;

            .parentItemsHeadLeftBlock {
                display: flex;
                flex-direction: column;
                margin: 10px 0;
            }
        }

        .itemsBlock {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;

            .itemBlock {
                width: 100%;
                margin: 0;
                padding: 10px;
                border-radius: 5px;
                background: #fff;
                display: flex;
                flex-direction: column;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0);
                cursor: pointer;
                border: 1px solid rgba(0,0,0,0.15);

                transition: 0.2s all;

                &:hover {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

                    transition: 0.2s all;
                }

                h4 {
                    font-weight: normal;
                    margin: 5px 0px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                    padding-bottom: 5px;
                    font-size: 12px;
                    color: #000;

                    b {
                        font-weight: bold;
                        font-size: 12px;
                        color: #a9a9a9;
                        width: 95px;
                        display: block;
                        float: left;
                    }
                }
            }
        }

        .itemsBlockAI {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 10px;

            .itemBlock {
                width: auto;
                margin: 0;
                padding: 2px 10px;
                border-radius: 5px;
                background: #fff;
                display: flex;
                flex-direction: column;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0);
                cursor: pointer;

                transition: 0.2s all;

                &:hover {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

                    transition: 0.2s all;
                }

                small {
                    font-size: 17px;
                    font-weight: bold;
                    margin: 0 10px 0 0;
                }

                h4 {
                    font-weight: normal;
                    margin: 5px 0px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                    padding-bottom: 5px;
                    font-size: 12px;
                    color: #000;

                    b {
                        font-weight: bold;
                        font-size: 12px;
                        color: #a9a9a9;
                        width: 95px;
                        display: block;
                        float: left;
                    }
                }
            }
        }
    }
}

.LikeOutlinedIcon {
    color: green;
}

.DislikeOutlinedIcon {
    color: red;
}

.modalRealestate {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: flex-end;

    transition: 0.3s all;

    .contentRealestateOverflow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1;
    }

    .contentRealestate {
        width: 35vw;
        height: 100vh;
        z-index: 10;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 15px;
        background: #fff;

        .itemRealestate {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 15px 10px;
            background: #13243b;
            cursor: pointer;
            margin-bottom: 5px;
            border-radius: 5px;

            h4 {
                margin: 0px;
                color: #fff;
            }

            b {
                margin: 0px;
                margin-left: 10px;
                padding: 2px 10px;
                border-radius: 5px;
                background: #fff;
                color: #333;
            }
        }

        .itemRealestateWithHouses {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;

            b {
                margin: 0;
            }

            .itemRealestateHouse {
                background-color: rgba(255,255,255,0.77);
                width: 100%;
                box-sizing: border-box;
                padding: 10px;
                border-radius: 7px;
                margin: 4px 0;
            }
        }

        .awaitInfo {
            width: 100%;
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;

            .awaitInfoIcon {
                font-size: 34px;
                color: rgba(0, 0, 0, 0.4);
            }

            h6 {
                margin: 0px;
            }
        }
    }
}

.modalRealestateOpened {
    opacity: 1;
    visibility: visible;

    transition: 0.3s all;
}

.wrapper {
    width: 100%;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 15px 0;
    }

    .wrapperList {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 10px;

        .item {
            width: 100%;
            padding: 10px;
            background-color: #fff;
            border-radius: 5px;
            box-sizing: border-box;
            cursor: pointer;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            display: flex;
            flex-direction: column;

            transition: 0.2s all;

            &:hover {
                box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);

                transition: 0.2s all;
            }

            small {
                font-size: 13px;
                line-height: 15px;
                margin-bottom: 4px;
                color: #767676;
                font-weight: bold;
            }

            span {
                width: 100%;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #0e0e0e;
                margin-bottom: 4px;

                svg {
                    font-size: 12px;
                    margin-left: 5px;
                }

                small {
                    font-size: 12px;
                    font-weight: normal;
                    text-transform: initial;
                    color: #999999;
                    margin: 0 0 0 5px;
                    line-height: 15px;
                }
            }
        }
    }

    .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
}

.wrapperFilter {
    width: 100%;
    margin: 0 0 15px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4px;
}

.xmlInfoBlock {
    width: 100%;

    p {
        margin: 0;
        color: rgba(0, 0, 0, 0.43);
    }
}

.listAV {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    h5,
    h4 {
        margin: 0;
        font-size: 10px;
    }
}

.filter {
    width: 100%;
    margin: 15px 0;
    border: 1px solid rgba(194, 194, 194, 0.3);
    border-radius: 5px;
    box-sizing: border-box;
    padding:  10px;
    box-shadow: 0 0 12px rgba(126, 126, 126, 0.3);

    .loader {
        width: 100%;
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;

    }
}

.wrapperOwnerList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .itemOwnerRealestate {
        width: 100%;
        border: 1px solid rgba(0,0,0,0.12);
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 0 0 10px rgba(0,0,0,0.12);

        transition: .2s all;

        &:hover {
            box-shadow: 0 0 14px rgba(0,0,0,0.22);
            cursor: pointer;

            transition: .2s all;
        }

        h3, h4 {
            margin: 0;
        }

        h4 {
            font-size: 17px;
            color: rgba(0,0,0,0.64);
        }

        h3 {
            font-size: 18px;
            color: #111; 
        }
    }
}

.wrapperHeader {
    width: 100%;
    background-color: #fff8d7;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;

    h4 {
        margin: 0;
        color: #777777;
        font-size: 15px;
    }
}

.wrapperRealestateList {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;

    .itemRealestate {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        border: 1px solid #d8d8d8;
        border-radius: 5px;

        h4 {
            margin: 0;
            font-size: 14px;
        }

        .line {
            width: 100%;
            height: 1px;
            background-color: #ebebeb;
            margin: 4px 0 7px;
        }

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }
    }
}
