.item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    border: 1px solid #efefef;
    background: #f7f7f7;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 10px;
    position: relative;

    span.count {
        position: absolute;
        top: 0;
        margin-top: -12px;
        left: -9px;
        width: 25px;
        font-size: 10px;
        font-weight: bold;
        display: flex;
        height: 25px;
        background: #f7f7f7;
        border-radius: 50%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.23);
        justify-content: center;
        align-items: center;
    }

    &:hover .buttons {
        right: 0px;

        transition: 0.3s all;
    }

    .itemLeft {
        width: 90%;
        display: flex;
        flex-direction: column;

        h5 {
            margin: 5px 0;
            color: #999;
            font-size: 13px;
            font-weight: bold;
        }
    }

    .itemRight {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
        }
    }
}

.headPage {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99999;
    background-color: #f0f2f5;
    box-sizing: border-box;
    padding: 10px 5px;

    .optionsBlock {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 10px 0 0 0;
    }
}

.blockDefault {
    width: 100%;
    display: flex;
    height: 550px;
    justify-content: center;
    align-items: center;
}

.items {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 20px 0;
    grid-gap: 20px;
}

.inputSearch {
    width: 100%;
    height: 35px;
    padding: 0 15px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    margin-bottom: 15px;
}

.formChangeUrl {
    width: 100%;

    input[type='text'] {
        width: 250px;
        height: 25px;
        padding: 0 10px;
        font-weight: bold;
        font-size: 12px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        outline: none;
    }

    input[type='submit'] {
        border: none;
        outline: none;
        background: #1977f3;
        color: #fff;
        cursor: pointer;
        padding: 5px 15px;
        font-size: 11px;
        position: relative;
        top: -1px;
        letter-spacing: 1px;
    }
}

.noSelectRedirectEdit {
    background: transparent;
    padding: 0;
    box-sizing: border-box;

    transition: 0.3s all;
}

.selectRedirectEdit {
    background: #f1f1f1;
    box-shadow: 0 0 20px #c9c9c9;
    padding: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    transition: 0.3s all;
    margin: 20px 5px;
}

.input {
    width: 100%;
    margin: 6px 0;
    border-radius: 5px;
    height: 30px;
    outline: none;
    padding: 0 10px;
    border: 1px solid #999;
}
