.noDataBlock {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .nodataIcon {
        font-size: 33px;
        margin: 10px 0;
        color: rgba(0,0,0,0.15);
    }

    h5 {
        margin: 0;
        color: rgba(0,0,0,0.5);
        font-size: 16px;
        font-weight: normal;
        text-transform: uppercase;
    }
}