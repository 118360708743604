.chessWrapper {
    width: 100%;
    margin: 10px 0;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 15px 0;

        h1 {
            margin: 0;
            font-size: 20px;
        }
    }

    .tools {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .descriptionFlatColors {
            display: flex;
            flex-direction: row;
            gap: 10px;

            span {
                display: block;
                padding: 10px 20px;
                border-radius: 5px;
            }
        }
    }
}

.form {
    margin-top: 15px;

    .formInput {
        margin-bottom: 5px;
    }
}

.selectBlockContent {
    width: 100%;
    height: auto;
    position: relative;
    user-select: none;

    .selectBlockImage {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 2;

        img {
            width: 100%;
            height: auto;
        }
    }

    .polygon {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 108, 255, 0.7);
        z-index: 20;
    }

    .coordPoint {
        position: absolute;
        z-index: 25;
        width: 8px;
        height: 8px;
        cursor: pointer;
        background-color: #ffffff;
        opacity: 0.74;
        border: 0.3px solid #333;
        border-radius: 50%;

        &:hover {
            opacity: 0.99;
        }
    }
}

.image {
    width: 100%;
    display: block;
    margin: 0 auto;
    height: auto;
}

.wrapperItems {
    width: 100%;
    height: 100%;
}

.chess {
    width: 100%;
    height: calc(100vh - 250px);
    margin: 15px 0;
    position: relative;
    background-color: rgba(110, 56, 9, 0.24);

    .loadBlock {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockItems {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .blockItem {
            width: auto;
            height: 100%;
            display: inline-grid;
            margin: 20px 150px;
            box-shadow: 0 0 0px rgba(0, 0, 0, 0.5);
            border-radius: 15px;
            transition: 0.3s all;

            &:hover {
                box-shadow: 0 0 35px rgba(0, 0, 0, 0.35);
                transition: 0.3s all;
            }

            .blockHeader {
                width: 100%;
                box-sizing: border-box;
                padding: 15px 20px;
                background-color: #eef4ff;
                border-radius: 4px;

                .icons {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                }

                .top {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 0;
                    box-sizing: border-box;

                    h1 {
                        margin: 0;
                        font-size: 75px;
                    }

                    .toolIcon {
                        font-size: 64px;
                        cursor: pointer;
                    }
                }

                .bottom {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    gap: 15px;
                    margin-top: 5px;

                    .button {
                        width: 100%;
                        min-height: 115px;
                        font-size: 35px;
                    }
                }
            }

            .block {
                width: 100%;
                height: 100%;
                position: relative;
                padding: 20px;
                box-sizing: border-box;
                border: 1px solid #4b657f;
                border-radius: 4px;
                margin-top: 0px;
                background-color: #eef4ff;

                .wrapperGrid {
                    width: 100%;
                    position: relative;

                    .wrapperFloors {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: 0px;
                        left: -200px;

                        .wrapperFloor {
                            width: 175px;
                            height: 290px;
                            margin: 0 0 20px;
                            background-color: #dfdfdf;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            h5 {
                                font-size: 45px;
                                margin: 0;
                            }

                            p {
                                font-size: 22px;
                                color: rgba(0, 0, 0, 0.47);
                                margin: 0;
                            }
                        }
                    }

                    .grid {
                        width: 100%;
                        display: grid;
                        height: 100%;
                        gap: 20px;

                        .box {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                            background-color: #fff;
                            opacity: 1;
                            cursor: pointer;
                            box-sizing: border-box;
                            padding: 5px;
                            border: 1px solid #4b657f;
                            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
                            transition: 0.3s all;

                            &:hover {
                                box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
                                transition: 0.3s all;
                            }

                            .flatForm {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                gap: 5px;
                                position: relative;

                                .buttonForm {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;

                                    h1 {
                                        font-size: 55px;
                                    }

                                    .button {
                                        position: absolute;
                                        top: 5px;
                                        right: 5px;
                                        z-index: 15;
                                    }
                                }

                                .blockFormFlat {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 5px;
                                    position: relative;

                                    .gorizontInputs {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        gap: 5px;
                                    }
                                }
                            }
                        }
                    }
                }

                .flat {
                    width: 100%;
                    height: 100%;
                    background-color: orange;
                }
            }
        }
    }
}

.priceIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;

    img {
        width: 80%;
        height: auto;
    }
}

.line {
    width: 60%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.75);
    margin: 15px auto;
}

.buttonUpload {
    position: relative;
    margin-top: 15px;

    .uploadInput {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0;
    }
}

.blockDelete {
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .blockDeleteDescription {
        margin-bottom: 5px;
    }

    .blockFloorButtons {
        display: flex;
        width: 100%;
        gap: 10px;
    }
}

.contextMenuBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
