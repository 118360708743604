.hotspot-button {
    width: max-content;
    height: max-content;
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 7px;
    position: absolute;
    z-index: 1;
    top: calc(100% - 20px);
    left: calc(100% - 20px);
    margin-top: 0;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 15px;
    color: #444;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -o-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
}

.hotspot-button:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.22);
    background-color: rgba(255, 255, 255, 1);

    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -o-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
}

.hotspot-wrapper {
    width: max-content;
    height: 0px;
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 101.5%;
    z-index: 1000;
    border-radius: 7px;
    z-index: 100000;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    transition: 0.57s all;
    -webkit-transition: 0.57s all;
    -o-transition: 0.57s all;
    -moz-transition: 0.57s all;
    -ms-transition: 0.57s all;
}

.hotspot-button:hover .hotspot-wrapper {
    height: 220px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.22);

    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -o-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
}

.hotspot-button:hover .hotspot-wrapper-product {
    height: 240px;
}

.hotspot-wrapper h5 {
    margin: 0 0 7px 0;
    font-weight: bold;
}

.hotspot-content {
    width: max-content;
    height: max-content;
    box-sizing: border-box;
    padding: 7px;

    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -o-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
}

.pnlm-hotspot-base {
    z-index: 1;
}

.pnlm-hotspot-base:hover {
    z-index: 100;
}

.hotspot-cover {
    width: 200px;
    height: 150px;
    border-radius: 7px;
    margin: 10px auto;
}

.hotspot-button-product {
    padding: 0;
    background: transparent;
}

.hotspot-button-product-icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    background: url(https://files.logovo.kz/uploads/product.png) center / contain
        no-repeat;
}
