.formRealtor {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 15px;
    background-color: #fff;
    padding: 15px 0;
    border-radius: 4px;

    .block {
        width: 100%;
        padding: 10px 0;
        box-sizing: border-box;
        margin: 7px 0;
    }

    input {
        width: 100%;
        margin: 5px 0;
    }

    input[type='submit'] {
        width: 170px;
        height: 40px;
        cursor: pointer;
        outline: none;
        background: #a4d14c;
        border-bottom: 3px solid #333;
        color: #fff;
        border-radius: 5px;
    }

    .formRealtorPassword {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        input {
            width: 49.5%;
        }
    }

    section {
        h5 {
            font-size: 15px;
            font-weight: bold;
            color: #4e719e;
            margin-top: 20px;
        }

        span {
            isplay: block;
            padding: 6px 20px;
            background: #1977f3;
            cursor: pointer;
            width: fit-content;
            color: #fff;
            border-radius: 4px;
        }
    }
}

.sectionSettings {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #f7f7f7;
    border-radius: 5px;

    p {
        margin: 3px 0;
        color: #ababab;
    }

    .sectionSetting {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .sectionSettingInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        box-sizing: border-box;
        padding: 15px;
        background-color: #eee;
        border-radius: 4px;

        h2 {
            margin: 0;
        }
    }
}

.modalRealEstate {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.77);
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.2s all;

    .modalRealEstateOverlay {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .modalRealEstateItems {
        width: 60vw;
        height: 90vh;
        background: #fff;
        overflow-y: auto;
        box-sizing: border-box;
        position: relative;
        z-index: 100;
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px;

        .modalRealEstateItem {
            width: 100%;
            height: 230px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            transition: 0.2s all;

            &:hover {
                .modalRealEstateItemOverlow {
                    background: rgba(255, 255, 255, 0);

                    transition: 0.3s all;
                }

                h3 {
                    padding: 10px 15px;
                    background: rgba(255, 255, 255, 0.88);
                    border-radius: 7px;

                    transition: 0.27s all;
                }
            }

            h3 {
                display: block;
                padding: 0;
                background: transparent;
                z-index: 100;
                text-align: center;
                font-size: 17px;

                transition: 0.27s all;
            }

            .modalRealEstateItemOverlow {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(255, 255, 255, 0.87);

                transition: 0.3s all;
            }
        }
    }
}

.RealEstateItems {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    .RealEstateItem {
        width: 100%;
        height: 180px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        h4 {
            display: flex;
            width: 100%;
            height: 50px;
            background: rgba(255, 255, 255, 0.7);
            justify-content: center;
            align-items: center;
            color: #333;
            text-align: center;
            margin: 0;
            font-size: 15px;
            font-weight: bold;
        }
    }
}

.modalRealEstateOpened {
    opacity: 1;
    visibility: visible;

    transition: 0.2s all;
}

.listRealtors {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    box-sizing: border-box;
    padding-right: 5px;

    .itemRealtor {
        width: 100%;
        box-sizing: border-box;
        padding: 14px 7px;
        background: #f3f5f8;
        box-shadow: 0 0 14px rgba(115, 82, 199, 0.28);
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        transition: 0.2s all;

        &:hover {
            box-shadow: 0 0 20px rgba(115, 82, 199, 0.48);
            transition: 0.2s all;
        }

        .Dropdown {
            position: absolute;
            top: 10px;
            right: 5px;
            z-index: 1000;
        }

        h6 {
            font-weight: bold;
            color: #222;
        }

        h5 {
            margin: 0;
        }

        .itemRealtorBottons {
            display: flex;
            flex-direction: row;
            background: white;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 5px;
        }
    }
}

.pageLeads {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    min-height: 90vh;

    .content {
        width: 100%;
        margin: 10px 0;

        .blockInfo {
            width: 100%;
            height: 450px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.companyItems {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #4079c6;
        margin-bottom: 2px;
        border-radius: 5px;
        cursor: pointer;

        p,
        a {
            margin: 0;
            font-weight: bold;
            color: #4079c6;
        }

        .line {
            width: 1px;
            height: 60px;
            background-color: #4079c6;
        }

        .itemSection {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                text-align: center;
                line-height: 15px;
            }
        }
    }
}

.inputFormLead {
    width: 100%;
    margin-bottom: 10px;
}

.blockInfoStat {
    width: 100%;
    margin: 15px 0;
    background-color: #eee;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 5px;
}
