.listCategories {
	width: calc(100% - 30px);
	display: flex;
	flex-direction: column;
	position: absolute;
	height: calc(100% - 30px);
	overflow-y: auto;

	.itemCategory {
		width: 100%;
		padding: 0 10px 0 10px;
		box-sizing: border-box;
		background: #eee;
		border-radius: 5px;
		margin-bottom: 5px;
		background:rgba(255,255,255,0.66);
		border: 1px solid rgba(255, 255, 255, 0);
		cursor: pointer;
		transition: .2s all;

		h4 {
			display: flex;
			flex-direction:row;

			b {
				display: block;
				padding: 5px;
				border-radius: 7px;
				background: #1b6ad3;
				margin-left: 10px;
				font-size: 12px;
				color: #fff;
			}
		}

		section {
			width:100%;
			padding:11px 5px ;
			box-sizing: border-box;
			border-radius:5px;
			border: 1px solid rgba(0, 0, 0, 0);
			transition: .2s all;

			&:hover {
				border: 1px solid rgba(0, 0, 0, 0.05);
				transition: .2s all;
			}
		}

		.childCategory {
			width: 100%;
			padding: 10px 10px 0 10px;
			box-sizing: border-box;
			background: #eee;
			border-radius: 5px;
			cursor: pointer;
			transition: .2s all;

			&:hover {
				background: #ececec;
				transition: .2s all;
			}

		}

		&:hover {
			background: #ececec;
			transition: .2s all;
		}

		h4 {
			margin: 0px;
		}
	}
}