.wrapper {
    width: 100%;
    display: block;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    h1 {
        color: #4e719e;
        font-weight: bold;
        font-size: 18px;
        margin: 0;
    }
}

.formItem {
    margin-bottom: 5px;
}

.pagination {
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vt_list {
    width: 100%;

    .vt_item {
        width: 100%;
        padding: 15px 15px;
        border-radius: 10px;
        box-sizing: border-box;
        box-shadow: 0 0 3px rgba(115, 82, 199, 0.28);
        grid-gap: 20px;
        margin: 10px 0;
        cursor: pointer;
        display: grid;
        border: 1px solid rgba(0, 0, 0, 0.19);
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;

        transition: 0.3s all;

        &:hover {
            box-shadow: 0 0 15px rgba(115, 82, 199, 0.48);

            transition: 0.3s all;
        }

        b,
        p {
            margin: 0;
            padding: 0;
        }

        b {
            margin-right: 15px;
            display: block;
            width: max-content;
        }
    }
}

.form {
    width: 100%;
    margin: 0 0 30px 0;

    .header {
        width: 100%;
        margin-bottom: 20px;

        h1 {
            margin: 0;
            color: #4e719e;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .blockSettings {
        width: 100%;
        margin: 20px 0;

        input {
            width: 100%;
            margin: 5px 0;
        }
    }
}

.pointsBlock {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    .point {
        width: 100%;
        border-radius: 7px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        opacity: 0.47;

        transition: 0.3s all;

        &:hover {
            opacity: 1;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);

            transition: 0.3s all;
        }

        h5 {
            font-size: 14px;
        }
    }
}

.pointBlockProduct {
    width: 100%;
    height: 160px;
}

.roomTypes {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    box-sizing: border-box;
    padding: 10px;

    span {
        width: auto;
        height: auto;
        background-color: #fff;
        color: #333;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        display: block;
        padding: 15px 10px;
        cursor: pointer;
        border-radius: 7px;
        text-align: center;

        transition: 0.3s all;

        &:hover {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.46);
            background-color: #1677ff;
            color: #fff;
            transition: 0.3s all;
        }
    }
}

.Pannellum {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 0 25px rgba(64, 121, 198, 0.42);

    > section > div {
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }

    > section {
        width: 100%;
        height: 850px;
        border-radius: 15px;
        position: relative;

        > div.layout {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 100;

            border-radius: 10px;
            box-sizing: border-box;
            width: fit-content;
            height: fit-content;

            img {
                width: 255px;
                height: auto;
                border-radius: 10px;
                background-color: rgba(255, 255, 255, 0.87);
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
            }

            .triangleView {
                width: 20px;
                height: 20px;
                background-color: rgba(64, 121, 198, 0.48);
                position: absolute;
                border-bottom-right-radius: 100%;
                opacity: 1;
                visibility: visible;
                transform-origin: 0% 0%;
                top: 50%;
                left: 50%;
            }

            .triangleViewShow {
                opacity: 1;
                visibility: visible;
            }

            .dot {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                position: absolute;
                z-index: 100;
                cursor: pointer;
                border: 1px solid transparent;
                background-color: rgba(22, 119, 255, 0.32);
                margin-top: -8px;
                margin-left: -6px;
                transition: 0.2s all;

                &:hover {
                    background-color: rgba(22, 119, 255, 0.82);
                    transition: 0.2s all;
                }
            }

            .dotActive {
                border: 1px solid #fff;
                background-color: rgba(22, 119, 255, 0.82);
                transition: 0.2s all;
            }
        }

        .loader {
            position: absolute;
            z-index: 20;
            width: 100px;
            height: 100px;
            background: #fff;
            border-radius: 40px;
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            display: flex;
            align-items: center;
            justify-content: center;

            animation-name: lgBounceLoading;
            animation-timing-function: ease;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        .scenes {
            width: calc(175px - 10px);
            height: calc(100% - 20px);
            background-color: rgba(255, 255, 255, 0.36);
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 500;
            overflow-y: auto;
            overflow-x: hidden;
            box-sizing: border-box;
            padding: 10px;

            .scene {
                width: 100%;
                height: 90px;
                border-radius: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                position: relative;

                transition: 0.09s all;

                .activeScene {
                    position: absolute;
                    color: #333;
                    font-size: 20px;
                    left: -14px;
                    top: 50%;
                    margin-top: -10px;
                    transition: 0.09s all;
                }

                .sceneRemove {
                    position: absolute;
                    top: 10px;
                    right: 5px;
                    z-index: 100;
                }
            }
        }
    }

    .noData {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff3dd;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;

        button {
            position: relative;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
                opacity: 0;
                cursor: pointer;
            }
        }
    }

    .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.3);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        .right {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .button {
                position: realtive;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }

        .left {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .button {
                position: realtive;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }
    }
}

.buttonScenes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    .buttonScene {
        width: 120px;
        height: 75px;
        cursor: pointer;
        opacity: 0.6;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0);

        transition: 0.3s all;

        &:hover {
            opacity: 1;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

            transition: 0.3s all;
        }
    }
}

.products {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin: 15px 0;

    .product {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.09);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        border-radius: 7px;
        cursor: pointer;
        box-sizing: border-box;
        padding: 10px;

        transition: 0.3s all;

        &:hover {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

            transition: 0.3s all;
        }

        .productPhoto {
            width: 100%;
            height: 100px;
        }

        h4 {
            font-weight: bold;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
        }
    }
}

@keyframes lgBounceLoading {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
        transform: scale(0.9, 1.1) translateY(-35px);
    }
    50% {
        transform: scale(1, 1) translateY(0);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}
