.blogItems {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    img {
        width: 100%;
        height: auto;
        min-height: 150px;
    }

    .blogItem {
        width: 100%;
        min-height: auto;
        border-radius: 3px;
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);
        position: relative;
        transition: 0.3s all;

        &:hover {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
            transition: 0.3s all;
        }
        .itemFooter {
            margin: 0;
            padding: 0 10px 10px 10px;

            span {
                color: rgba(0, 0, 0, 0.4);
            }
        }

        .Dropdown {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 100;
            width: 25px;
            height: 25px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.blockEditor {
    width: 100%;
    padding: 10px 0;
}

.selectStyle {
    width: 100%;
}
