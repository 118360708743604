.headerMain {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 15px 10px;
    position: sticky;
    top: 0;
    z-index: 800;

    a {
        display: flex;
        flex-direction: row;
        width: auto;
        align-items: center;

        img {
            margin: 0 15px 0 0;
        }
    }

    .rightHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;

        small {
            width: max-content;
            letter-spacing: 4px;
            line-height: 23px;
            margin: 0;
            color: #fff;
            background: #4079c6;
            padding: 5px 5px 5px 10px;
            margin-right: 20px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            flex-direction: row;

            span {
                display: flex;
                box-sizing: border-box;
                padding: 0;
                margin-left: 6px;
                margin-right: 4px;
                border-radius: 5px;
                text-transform: uppercase;
                font-size: 11px;
                background: none;
                height: auto;
                color: #fff;
                font-weight: bold;
                justify-content: center;
                align-items: center;
            }
        }

        span {
            display: flex;
            width: max-content;
            margin-left: 10px;
            box-sizing: border-box;
            padding: 0 20px;
            height: 36px;
            border-radius: 5px;
            text-transform: uppercase;
            font-size: 11px;
            background-color: #86c30d;
            color: #fff;
            font-weight: bold;
            justify-content: center;
            align-items: center;
        }

        span.superadmin {
            background-color: #0a2463;
        }
    }
}
