.blockPage {
    width: 100%;

    h3 {
        font-weight: normal;
    }
}


.Mortgage_wrapper {
    width: 100%;
    display: table;
  }
  
  .form {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
  }
  
  .form_add_setting {
    width: 100%;
    display: flex;
    flex-direction: row;
  
    > input {
      border:1px solid rgba(0,0,0,0.3);
      border-radius: 3px;
      outline: none;
      padding: 0 15px;
      width:45%;
    }
  }
  
  .list_mortgage {
    width: 100%;
  
    h2 {
      font-size: 18px;
      margin: 0;
    }
  
    .ItemMortgage {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.11);
      margin-bottom: 5px;
      border-radius: 4px;
      padding: 0 15px;
      box-sizing: border-box;
  
      input {
        margin:10px 0;
        border: 1px solid rgba(0,0,0,0.3);
        outline: none;
        border-radius: 4px;
      }
  
      h4 {
        margin:0px;
        padding: 10px 0;
        font-weight: normal;
      }
  
      .itemControllButtons {
        display: flex;
        flex-direction: row;
        margin-left: 15px;
  
        svg {
          cursor: pointer;
        }
  
        svg:nth-child(1) {
          margin-right:5px;
        }
      }
    }
  }
  
.wrapper {
  width: 100%;
}  
  

.list {
  width: 100%;

  .itemSetting {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    border: 1px solid rgba(0,0,0,0.11);
    border-radius: 4px;
    padding: 7px;
    box-sizing: border-box;

    h4 {
      font-size: 17px;
      margin: 0;
    }

    .itemSettingControl {

      .itemSettingControlIcon {
        margin-left: 7px;
        cursor: pointer;
      }
    }
  }
}

.formWithTA {
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    margin: 5px 0;
  }
}