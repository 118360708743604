.mainblock {
    width: 100%;

    .loadBlock {
        width: 100%;
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loadIcon {
            color: rgba(0, 0, 0, 0.17);
            font-size: 75px;
        }
    }

    .chartMultiLines {
        width: 100%;
        display: table;
        margin: 5px 0;
        background: #fff;
        padding: 15px;

        .chartMultiLineshead {
            width: 100%;
            display: flex;

            h2 {
                margin: 0 0 10px 0;
                font-size: 15px;
                font-weight: normal;
                color: #b7b7b7;
            }
        }
    }

    .mainblockSection {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
        min-height: auto;

        section {
            background: #fff;
            border-radius: 7px;
            box-sizing: border-box;
            padding: 10px;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.01);
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            border: 1px solid #e7e7e7;

            transition: 0.3s all;

            .icon {
                position: absolute;
                right: 7%;
                bottom: 21%;
                font-size: 82px;
                color: #f3f3f3;
            }

            h1 {
                margin: 0;
                font-size: 17px;
                color: #cfcfcf;
                font-weight: normal;
                z-index: 150;
            }

            h5 {
                color: #d5d5d5;
                font-size: 53px;
                margin: 0 0 17px;
                font-weight: normal;
            }

            &:hover {
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.164);
                cursor: pointer;

                transition: 0.4s all;
            }
        }
    }
}
