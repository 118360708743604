@import './fonts.css';

* {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: 'Gilroy-Medium';
}

body {
    overflow-y: scroll;
    overflow-x: hidden;
}

a:hover {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #333;
}

::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 20px;
}

::-moz-scrollbar {
    width: 8px;
}

::-moz-scrollbar-track {
    background: #333;
}

::-moz-scrollbar-thumb {
    background-color: #222;
    border-radius: 20px;
}

::-o-scrollbar {
    width: 8px;
}

::-o-scrollbar-track {
    background: #333;
}

::-o-scrollbar-thumb {
    background-color: #222;
    border-radius: 20px;
}

::-ms-scrollbar {
    width: 8px;
}

::-ms-scrollbar-track {
    background: #333;
}

::-ms-scrollbar-thumb {
    background-color: #222;
    border-radius: 20px;
}

body {
    background: #f3f5f8;
    width: 100%;
}

.app-main {
    width: 65vw;
    margin: 0 auto;
    background: #eee;
}

@media screen and (max-width: 2150px) {
    .app-main {
        width: 75vw;
    }
}

@media screen and (max-width: 1920px) {
    .app-main {
        width: 99vw;
    }
}

.app-admin-content {
    width: 100%;
    min-height: 100vh;
    background: #f9fafb;
    display: table;
    box-shadow: 0 0 30px rgba(51, 51, 51, 0.21);
}

header {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding-inline: 10px;
}

header h4 {
    margin: 0;
    font-size: 20px;
    color: rgba(51, 51, 51, 0.82);
    font-weight: normal;
}

.right-content-side {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 4%);
    border-radius: 20px;
    margin: 10px 0;
    padding: 15px 20px;
}

.right-content-side section span.logout {
    cursor: pointer;
    display: block;
    color: rgba(255, 255, 255, 0.62);
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
}

.right-content-side section {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 7px;
    box-sizing: border-box;
    cursor: pointer;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.right-content-side section:hover {
    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.sidebar-icon-main {
    margin-right: 10px;
    color: #c0cbd7;
}

.right-content-side section > a {
    font-size: 19px;
    color: #6a7d95;
}

.right-content-side section:hover > a {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.58);
}

.sidebar-icon-add {
    color: #fff;
}

.right-content-side section span a {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #1977f3;
    justify-content: center;
    align-items: center;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.right-content-side section span a:hover {
    background: #5092ea;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.add-icon {
    color: #fff;
    font-size: 22px;
}

.skeleton-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.skeleton-block-item {
    width: 32%;
}

.block-info-link-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.block-info-link-bar a {
    color: #4e719e;
    font-weight: bold;
    font-size: 13px;
}

.page-create-apartment {
    width: 100%;
}

.ApartmentСomplex {
    width: 100%;
    box-sizing: border-box;
}

.ant-tabs {
    overflow: inherit;
}

.ant-notification {
    z-index: 99999999;
}

.ant-notification-notice-description {
    margin-left: 48px;
    font-size: 14px;
    white-space: pre-line;
    line-height: 22px;
    color: #1890ff;
    margin-top: 15px;
}

.ApartmentСomplex h4 {
    margin: 15px 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.67);
}

.ApartmentСomplex h2 {
    font-size: 18px;
}


.input-section-form {
    width: 100%;
}

.select-option-style {
    width: 100%;
}

.select-option-style-label {
    font-size: 22px;
}

.select-option-style option {
}

.MuiFormLabel-root {
    font-size: 22px;
}

.cities-page-block h2 {
    margin: 15px 0;
    font-size: 16px;
}

.cities-page-block h1,
.page-apartment h1,
.page-create-apartment h1,
.page-block-header h1,
h1.title-page,
.statistic-block h1 {
    color: #4e719e;
    font-weight: bold;
    font-size: 18px;
}

.MuiAvatar-colorDefault {
    color: #fafafa;
    background-color: #63a0cc;
}

.list-item-city {
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 0 15px;
    display: table;
    box-shadow: 0 0 9px #dedede;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.list-item-city:hover {
    background: #fff;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.list-item-city-edit-input {
    width: 45%;
    height: 30px;
    margin-bottom: 5px;
    font-size: 16px;
    padding: 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.page-apartment h2 {
    font-size: 17px;
}

.drag-photo-complex,
.drag-photo-complex > div {
    width: 100%;
    height: 150px;
    background: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgb(0 0 0 / 15%);
    border-radius: 7px;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

.drag-photo-complex-content h2 {
    font-size: 18px;
}

.drag-photo-complex p {
    width: 35%;
    display: block;
    margin: 0;
    text-align: center;
}

.section-block-photos {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ant-modal-wrap {
    z-index: 999999;
}

.photo-apartment-url {
    width: 285px;
    height: 150px;
    display: flex;
}
.ant-modal-mask {
    z-index: 999999;
}

.ant-message {
    z-index: 9999999;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spin {
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.page-apartment-grid {
    width: 100%;
}

.list-complex-content-block {
    width: 100%;
    display: flex;
}

.apartment-image-block {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.apartment-image-block img {
    width: 100%;
    height: 100%;
}

.dragging {
    z-index: 111111;
    transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);

    transition: 0.15s all;
    -o-transition: 0.15s all;
    -ms-transition: 0.15s all;
    -moz-transition: 0.15s all;
    -webkit-transition: 0.15s all;
}

.item-card-apart {
    display: table;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
}

.drag-overlay-info {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999999999;
}

.apartment-block-content-item {
    width: 100%;
    height: 200px;
    position: relative;
    cursor: pointer;
}

.apartment-block-content-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.88);
    backdrop-filter: blur(0px);
    box-sizing: border-box;
    padding: 15px;
    opacity: 0.58;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -webkit-transition: 0.3s all;
}

.apartment-block-content-item:hover .apartment-block-content-item-overlay {
    opacity: 1;
    backdrop-filter: blur(6px);

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -webkit-transition: 0.3s all;
}

.apartment-block-content-item-overlay-top h2 {
    color: rgba(255, 255, 255, 0.78);
    margin: 0;
    font-size: 17px;
}

.apartment-block-content-item-overlay-bottom {
    opacity: 0;

    transition: 0.2s all;
}

.apartment-block-content-item:hover
    .apartment-block-content-item-overlay-bottom {
    opacity: 1;

    transition: 0.2s all;
}

.apartment-block-content-item-overlay-bottom small {
    color: rgba(155, 155, 155, 0.87);
}

.apartment-block-content-item-overlay-settings-right {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.apartment-block-content-item-overlay-settings-right p {
    color: #fff;
    margin: 0 7px 0 0;
}

.apartment-block-content-item-overlay-settings-right span,
.apartment-block-content-item-overlay-settings-right label {
    margin: 0;
}

.ant-form-item {
    width: 100%;
    margin-bottom: 5px;
}

.ant-form-item-label > label {
    max-height: 21px;
    position: relative;
    top: 6px;
}

.apartment-block-content-item-overlay-settings {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    background: rgb(255 255 255 / 16%);
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 10px;
}

.apartment-block-content-item-overlay-settings-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.apartment-edit-icon {
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.apartment-delete-icon {
    color: red;
    width: 45px;
    height: 45px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.blocks-form-content {
    width: 100%;
    background: rgba(0, 0, 0, 0.06);
}

.drag-block-content {
    height: 700px;
    width: 100%;
    position: relative;
    overflow: auto;
    padding: 0;
}

.drag-block-content-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.handle-content {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    background: transparent;
    opacity: 0;
    display: none;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.handle-content-openned {
    opacity: 1;
    display: block;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.Draggable-style {
    display: table;
    width: auto;
}

.react-draggable {
    display: inline-block;
}

.devider-block {
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.6);
    margin: 20px 0;
}

.blocks-content-visual {
    width: 100%;
    margin: 0 auto;
    margin-top: 70px;
}

.blocks-content-visual h1 {
    margin: 0;
    color: rgba(0, 0, 0, 0.67);
    font-size: 22px;
}

.upload-photo-block h1 {
    margin: 10 0 5px 0;
    font-size: 17px;
}

.apartment-block-content-item-overlay-bottom-link-blocks {
    margin: 10px 0;
    display: flex;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    font-size: 12px;
    padding: 5px 10px;
    position: relative;
    margin-right: 10px;
    border-radius: 50%;
    font-weight: bold;
    border-radius: 4px;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.icon-settings-apart-complex-sections {
    background: url(../../assets/icons/7.svg) center / contain no-repeat;
}

.icon-settings-apart-complex-prices {
    background: url(../../assets/icons/8.svg) center / contain no-repeat;
}

.icon-settings-apart-complex-chess {
    background: url(../../assets/icons/9.svg) center / contain no-repeat;
}

.item-card-apart-active {
    opacity: 0.7;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.drop {
    opacity: 1;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.apartment-block-content-item-overlay-bottom-link-blocks:after {
    content: attr(data-title);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    line-height: 16px;
    bottom: 70%;
    left: 10%;
    background: #222;
    border-radius: 3px;
    padding: 7px 10px;
    color: #fff;
    visibility: hidden;
    min-width: 100px;
    opacity: 0;
    border: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 111;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.apartment-block-content-item-overlay-bottom-link-blocks:hover:after {
    content: attr(data-title);
    bottom: 105%;
    visibility: visible;
    opacity: 1;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.block-image-map-style-item {
    display: table;
    position: relative;
    cursor: crosshair;
    border-radius: 5px;
    z-index: 1;
    background: transparent;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.block-image-map-style-item:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0);
    z-index: 1000;
    background: rgba(255, 255, 255, 0);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.block-image-map-style-item img {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 1;
}

.block-image-map-style-item svg:hover g,
.block-image-map-style-item svg:hover polygon,
.block-image-map-style-item svg:hover path {
    fill: rgba(0, 0, 0, 0.5);
    stroke: rgba(0, 0, 0, 0.2);
}

.wrapper-class-name {
    width: 100%;
    height: auto;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.wrapper-class-name:hover {
    stroke: black;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.wrapper-class-name svg {
    width: 100%;
    height: auto;
    max-height: 255px;
}

.handle-content-info {
    width: 100%;
    display: flex;
    min-height: 130px;
    box-sizing: border-box;
    flex-direction: column;
    padding: 5px;
    border: 1px solid #86c30c;
    border-radius: 5px;
    background: #eee;
}

.handle-content-info h4 {
    font-size: 17px;
    font-weight: bold;
    margin: 5px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.handle-content-info h4 small {
    width: 55px;
    display: block;
    color: #000;
}

.block-remove-item {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    background: #ad1a1a;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 10;
}

.block-remove-item svg {
    color: #fff;
}

.block-change-image-item {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    background: #41a917;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 35px;
    right: 10px;
    z-index: 10;
    overflow: hidden;
}

.block-change-image-item input {
    opacity: 0;
    position: relative;
    z-index: 111;
}

.setting-icon-block {
    width: 25px;
    height: 25px;
    border-radius: 13px;
    color: #1b1a1a;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 100;
    cursor: pointer;
}

.block-change-image-item svg {
    color: #fff;
    position: absolute;
}

.update-status-block-for-blocks-apartments {
    width: 100%;
    background: red;
    margin: 15px 0;
    padding: 15px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.update-status-block-for-blocks-apartments h4 {
    margin: 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
}

.link-flats-block {
    width: 100%;
}

.link-flats-block a {
    display: flex;
    padding: 5px 10px;
    background: #86c30d;
    margin-top: 10px;
    border-radius: 3px;
    color: #444;
}

.form-flat-block {
    width: 100%;
}

.line-block {
    width: 100%;
    height: 1px;
    background: rgba(35, 35, 35, 0.18);
    margin: 40px 0;
}

.block-flat-files {
    width: 100%;
}

.block-flat-files select {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.block-flat-files section {
    padding: 15px 0px;
    box-sizing: border-box;
    margin-bottom: 10px;
    width: 100%;
    background: #fff;
    border-radius: 4px;
}

.block-flat-files h4 {
    font-size: 14px;
    margin: 0 0 5px 0;
}

.block-flat-files h3 {
    font-size: 14px;
    margin: 5px 0 5px 0;
}

.paid-block {
    background: rgb(192 255 0 / 3%);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed rgb(0 0 0 / 17%);
}

.free-block {
    margin-top: 10px;
    background: rgb(228 228 228 / 22%);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px dashed rgb(0 0 0 / 15%);
}

input.price-file-input {
    width: 100%;
    height: 40px;
    margin-top: 7px;
    outline: none;
    border: 1px solid rgb(0 0 0 / 16%);
    border-radius: 5px;
    padding: 0 10px;
}

input.button-upload-file {
    width: 100%;
    height: 40px;
    margin-top: 7px;
    outline: none;
    background: #4079c6;
    border: none;
    color: #fff;
    border-radius: 4px;
}

.paid-block h4,
.free-block h4 {
    margin: 10px 0 0 0;
}

.plan-block-file {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.02);
    margin-bottom: 5px;
    margin-top: 13px;
    box-sizing: border-box;
    padding: 15px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
}

.plan-block-file h4 {
    font-weight: normal;
    font-size: 15px;
}

.plan-block-file img {
    width: fit-content;
    height: 236px;
    display: block;
    margin: 0 auto;
}

.ant-image-preview-root {
    position: relative;
    /*z-index: 9999999999;*/
}
.ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.77);
}
.ant-image-preview-img {
    background: #fff;
    padding: 20px;
}

.plan-block-file input[type='file'] {
    width: 100%;
    background: white;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 8%);
}

.images-block-interior-flat {
    width: 100%;
}

.image-interior-flat {
    width: 32%;
    height: 150px;
    margin: 7px 1% 7px 0;
    float: left;
    position: relative;
}

.image-interior-flat span {
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #e00;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 25px;
    color: #fff;
}

.create-flat-block-form {
    width: 100%;
    margin-bottom: 25px;
    background-color: #fff;
    padding: 0;
    border-radius: 4px;
}

.content-items-flat {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    grid-auto-rows: minmax(150px, auto);
}

.block-item-flat {
    background: #fff;
    box-shadow: 0 0 10px rgb(51 51 51 / 0%);
    border: 1px solid rgb(51 51 51 / 15%);
    border-radius: 6px;
    padding-top: 10px;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.block-item-flat:hover {
    box-shadow: 0 0 10px rgb(51 51 51 / 30%);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.block-item-flat section {
    width: 100%;
    overflow: hidden;
}

.block-item-flat-information {
    padding: 0 7px;
    width: 100%;
    box-sizing: border-box;
}

.block-item-flat-information p {
    margin: 10px 0 0 0;
}

.block-item-flat-information span {
    color: #8a8a8a;
}

.block-item-flat-button-delete {
    color: red;
    cursor: pointer;
    font-size: 15px;
}

.block-item-flat-button-edit {
    cursor: pointer;
    font-size: 15px;
}

.statistic-block content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.statistic-block content section {
    background: #fffffe;
    box-shadow: 0 0 6px rgb(68 68 68 / 9%);
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.statistic-block content section h1 {
    margin: 15px;
    font-size: 12px;
    text-align: center;
}

.statistic-block content section h5 {
    margin: 0;
    padding: 10px 22px;
    background: rgba(255, 255, 255, 0.68);
    border-radius: 5px;
    color: #333;
    font-size: 27px;
    font-weight: bold;
}

.statistic-block h4 {
    margin: 10px 0 15px 0;
    font-size: 17px;
}

.statistic-block h4 span {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.47);
    font-size: 13px;
}

.block-users-items {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
}

.user-block-avatar {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.051);
}

.user-information {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.user-information h4 {
    margin: 10px 0 5px;
    color: rgba(0, 0, 0, 0.78);
}

.user-information h5 {
    margin: 0;
    color: rgba(0, 0, 0, 0.58);
}

.user-information span {
    display: block;
    padding: 5px 10px;
    text-align: center;
    font-size: 9px;
    text-transform: uppercase;
    background: #4079c6;
    border-radius: 5px;
    margin: 10px 0 0px;
    color: #fff;
    text-shadow: 1px 1px 2px #333;
}

.user-date-regisration {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.user-date-regisration h4 {
    text-align: center;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.44);
}

.item-user-number {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #555;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 9px;
    font-weight: bold;
}

.item-user {
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 0 transform;
    position: relative;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -webkit-transition: 0.3s all;
}

.item-user:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -webkit-transition: 0.3s all;
}

a.link-button-360 {
    padding: 5px 8px;
    color: #000;
}

.icon-button-360 {
    transform: scale(1.3);
    -o-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
}

.form-360-block {
    width: 100%;
    display: table;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 10px 10px rgb(0 0 0 / 5%);
    margin-bottom: 20px;
}

.form-360-block section {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    background: #f9f9f9;
    border: 2px dashed #e0e0e0;
    margin-bottom: 15px;
}

.form-360-block section h5 {
    margin: 0 0 10px 0;
    padding: 0;
}

.form-360-block section span {
    display: table;
    background: #9ed812;
    padding: 10px 15px;
    color: #fff;
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgb(51 51 51 / 35%);
}

.block-view-360 {
    width: 100%;
    display: table;
}

.block-view-360-content {
    width: 100%;
    display: table;
    position: relative;
}

.controll-block-360 {
    width: 100%;
    display: table;
    margin-top: 15px;
}

.controll-block-360 span {
    width: block;
    float: left;
    width: 20%;
    height: 110px;
    margin-bottom: 5px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
    position: relative;
}

.delete-360 {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
    cursor: pointer;
    display: block;
    padding: 7px 7px 5px;
    background: #9e1515;
    border-radius: 45%;
    color: #fff;
}

.event-photo-select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.block-view-360-content span.button-new {
    position: absolute;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    padding: 10px 0;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: #444;
    opacity: 0.5;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -webkit-transition: 0.3s all;
}

.block-view-360-content span.button-new:hover {
    opacity: 1;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -webkit-transition: 0.3s all;
}

.block-view-360-content span.button-new-scene {
    bottom: 50px;
}

.block-view-360-content span.button-new-point {
    bottom: 5px;
}

.modal-information-point {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    background: #fffffe;
    border-radius: 5px;
    margin-bottom: 10px;
}

.block-form-new-scene {
    width: 100%;
    background: white;
    padding: 10px 0;
    box-sizing: border-box;
    border-radius: 5px;
    display: table;
}

.scene-item-photo {
    width: 25%;
    height: 130px;
    float: left;
    cursor: pointer;
    position: relative;
    z-index: 1;
    border: 1px solid #232323;

    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.scene-item-photo:hover {
    z-index: 10;

    transform: scale(1.07);
    -o-transform: scale(1.07);
    -ms-transform: scale(1.07);
    -moz-transform: scale(1.07);
    -webkit-transform: scale(1.07);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.selected-scene-photo {
    width: 30%;
    height: 150px;
}

.selected-scene-photo-block {
    width: 100%;
    display: table;
    background: #ececec;
    padding: 15px;
    box-sizing: border-box;
    margin: 10px 0 0 0;
    float: left;
    border-radius: 10px;
    box-shadow: 0 0 10px 10px #e2e2e28a;
}

.selected-scene-photo-block h4 {
    margin: 0 0 5px 0;
}

.points-360-block {
    width: 100%;
    display: table;
    box-sizing: border-box;
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 10px;
}

.photo-scene-block-preview {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-point-icon {
    color: rgba(0, 0, 0, 0.35);
}

.item-section-point-360-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.desc-scene-block-preview {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
}

.desc-scene-block-preview p {
    margin: 0;
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
}

.item-section-point-360 {
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    background: rgb(239, 239, 239);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    transition: 0.2s all;
}

.item-section-point-360:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.07);

    transition: 0.2s all;
}

.grid-points-main-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
    height: auto;
    overflow-y: auto;
}

.item-section-point-360 span {
    width: 100%;
    display: table;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 11px;
    color: #1977f3;
}

.item-section-point-360 span.remove-point-360 {
    border: 0 solid #ceec92;
    margin: 0;
    color: red;
}

.item-section-point-360-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.block-form-description-product {
    width: 100%;
}

.block-form-description-product input {
    width: 100%;
    height: 31px;
    outline: none;
    padding: 0 15px;
    margin-bottom: 7px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
}

.section-settings-block {
    width: 100%;
    display: table;
    margin-bottom: 0;
}

.section-settings-block-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    background: #ffffff;
    box-shadow: 0 0 15px rgb(51 51 51 / 19%);
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px;
}

.section-download-settings-block-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    background: #ffffff;
    box-shadow: 0 0 15px rgb(51 51 51 / 4%);
    box-sizing: border-box;
    padding: 10px;
}

.section-settings-block-head h3,
.section-download-settings-block-head h3 {
    margin: 0;
    font-size: 17px;
}

.section-settings-block-body {
    width: 100%;
    display: table;
    background: #fff;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.setting-block {
    width: 100%;
    display: table;
}

.setting-block p {
    margin: 0;
    color: #bdbdbd;
}

.setting-block-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.setting-block-input h5 {
    margin: 0 0 0 10px;
    font-size: 18px;
}

.main-photo-panorama {
    width: 100%;
    display: table;
    margin: 0 auto;
}

.main-block-with-pamorama {
    position: relative;
    width: 100%;
    display: table;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.main-block-with-pamorama img {
    width: 100%;
    height: auto;
}

h1.description-text-panorama {
    display: block;
    padding: 10px 5px;
    background: #89b4ec;
    color: #fff;
    text-shadow: 1px 1px 0 rgb(0 0 0 / 10%);
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}

.panorama-buttons-controll {
    width: 100%;
    display: flex;
}

.panorama-buttons-controll span {
    display: block;
    cursor: pointer;
    padding: 5px 10px;
    text-transform: uppercase;
    background: #1977f3;
    margin-right: 10px;
    color: #ffffff;
    border-radius: 3px;
    font-size: 10px;
}

.area-block-panorama-select {
    width: 100%;
    height: 100%;
    background: rgb(135 196 14 / 0%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.05s all;
    -o-transition: 0.05s all;
    -ms-transition: 0.05s all;
    -moz-transition: 0.05s all;
    -webkit-transition: 0.05s all;
}

.area-block-panorama-select-background {
    background: rgb(135 196 14 / 58%);
}

.area-block-panorama-select h4 {
    color: #fff;
}

.area-block-panorama-select-preview:hover {
    background: rgb(0 0 0 / 62%);
    z-index: 1000;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -webkit-transition: 0.3s all;
}

.dot-point-area {
    width: 5px;
    height: 5px;
    cursor: pointer;
    border-radius: 50%;
    background: #7a15ff;
    z-index: 1500;
    position: absolute;
    margin-top: -2.5px;
    margin-left: -2.5px;

    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);

    transition: 0.1s all;
    -o-transition: 0.1s all;
    -ms-transition: 0.1s all;
    -moz-transition: 0.1s all;
    -webkit-transition: 0.1s all;
}

.event-points-block {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.dot-point-area span {
    width: 15px;
    height: 15px;
    background: #b52626;
    border-radius: 50%;
    cursor: pointer;
    bottom: 0px;
    position: absolute;
    left: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.dot-point-area:hover span {
    opacity: 1;
    visibility: visible;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.dot-point-area span svg {
    color: #fff;
    font-size: 12px;
}

.list-block-main-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    margin-top: 20px;
    border-top: 2px solid #888;
}

.block-list-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
}

.block-list-item > div {
    display: flex;
    flex-direction: row;
}

.block-list-item > div span {
    margin-right: 15px;
    cursor: pointer;
    display: block;
}

.block-list-item:nth-child(even),
.flat-main-item:nth-child(even) {
    background: #fff;
}

.block-list-item:nth-child(odd),
.flat-main-item:nth-child(odd) {
    background: #f9f9f9;
}

.block-list-item p {
    margin: 0;
    padding: 0;
}

.coord-point-block-with-icons {
    width: 200px;
    height: 50px;
    background: #86c30d;
    position: absolute;
    z-index: 100;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
}

.icon-point-preview {
    width: 30px;
    height: 30px;
    border-radius: 40%;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.icon-point-preview:hover {
    width: 30px;
    height: 30px;
    background-color: #000;
    border-radius: 50%;
    padding: 5px;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.icon-point-preview-1 {
    background: url(../../assets/icons/1.png) center / 55% no-repeat;
}

.icon-point-preview-2 {
    background: url(../../assets/icons/2.png) center / 55% no-repeat;
}

.icon-point-preview-3 {
    background: url(../../assets/icons/3.png) center / 55% no-repeat;
}

.icon-point-preview-4 {
    background: url(../../assets/icons/4.png) center / 55% no-repeat;
}

.icon-point-preview-5 {
    background: url(../../assets/icons/5.png) center / 55% no-repeat;
}

.icon-point-preview-6 {
    background: url(../../assets/icons/6.png) center / 55% no-repeat;
}

.icon-point-preview-position {
    position: absolute;
    z-index: 2000;
    width: 40px;
    border-radius: 50%;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 41%;
}

.icon-point-preview-position:hover {
    width: 40px;
    height: 40px;
    z-index: 3000;
}

.point-title {
    position: absolute;
    left: 2%;
    border-radius: 20px;
    z-index: -1;
    top: 0;
    display: flex;
    overflow: hidden;
    background: #0e0e0e;
    padding: 15px 5px;
    min-width: 170px;
    justify-content: center;
    align-items: center;
    height: 40px;
    opacity: 0;
    visibility: hidden;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.point-title p {
    margin: 0;
    padding: 0;
    color: #fff;
}

.icon-point-preview-position:hover .point-title {
    opacity: 1;
    left: 99%;
    visibility: visible;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;
}

.point-title-view {
    opacity: 1;
    left: 99%;
    visibility: visible;
}

span.block-icon-remove {
    position: relative;
    cursor: pointer;
    top: 2px;
    color: red;
}

.list-block-main-block h2 {
    font-weight: bold;
    font-size: 15px;
}

.tabs-blocks-and-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 20px 0 10px 0;
}

.tabs-blocks-and-block span {
    width: 130px;
    cursor: pointer;
    display: flex;
    padding: 7px 0;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    text-transform: uppercase;
    color: rgb(90 90 90 / 48%);
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: bold;

    transform: scale(1);
    -o-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);

    transition: 0.1s all;
    -o-transition: 0.1s all;
    -ms-transition: 0.1s all;
    -moz-transition: 0.1s all;
    -webkit-transition: 0.1s all;
}

.tabs-blocks-and-block span.active {
    background: #1977f3;
    color: #fff;
    box-shadow: 0 0 20px rgb(51 51 51 / 42%);
    z-index: 1;
    border-radius: 5px;

    transform: scale(1.05);
    -o-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);

    transition: 0.1s all;
    -o-transition: 0.1s all;
    -ms-transition: 0.1s all;
    -moz-transition: 0.1s all;
    -webkit-transition: 0.1s all;
}

.line-main-full-width {
    width: 100%;
    height: 2px;
    background: #8e8e8e;
}

.upload-floor-image-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.upload-floor-image-block p {
    display: block;
    cursor: pointer;
    padding: 5px 10px;
    background: #86c30d;
    border-radius: 5px;
    position: relative;
    top: 4px;
}

.section-upload-floor-image {
    box-sizing: border-box;
    padding: 10px;
    background: #ffffff;
    border: 1px dashed rgb(0 0 0 / 67%);
    box-shadow: 0 0 10px rgb(0 0 0 / 12%);
}

.plan-preview-photo-floor {
    width: 40%;
    height: 150px;
}

.plan-preview-photo-floor-main-block h5 {
    cursor: pointer;
    display: table;
    padding: 9px 10px;
    background: #86c30d;
    border-radius: 5px;
    text-transform: uppercase;
}

.plan-floor-main-block-with-points {
    width: 100%;
    display: table;
    position: relative;
    height: auto;
}

.plan-floor-main-block-with-points img {
    width: 100%;
    height: auto;
}

.section-with-plan-floor {
    width: 100%;
    display: table;
}

.section-with-plan-floor > span,
.section-with-plan-floor span {
    cursor: pointer;
    padding: 10px 15px;
    float: left;
    background: #4079c6;
    margin-top: 10px;
    border-radius: 5px;
    color: #fff;
}

.list-flats-block {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.flat-main-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.flat-main-item-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.block-icon-remove-flat {
    margin-right: 15px;
    cursor: pointer;
}

.header-page-main {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    width: 100%;
    padding: 15px 0;
    z-index: 888;
    padding-top: 7px;
}

a.template-link-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    background: #4079c6;
    border-radius: 5px;
    color: #fff;
}

a.template-link-block:hover {
    background: #4079c6;
}

a.template-link-block span {
    margin-right: 5px;
}

.search-block-complex-templates {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 30px rgb(211, 211, 211);
    margin: 0;
    position: sticky;
    top: 0px;
    z-index: 999;
}

.block-item-flat-button-template {
    position: relative;
    top: 1px;
}

.copy-floor-block {
    width: 100%;
    padding: 15px;
    background: #ffe78e;
    box-sizing: border-box;
    margin: 15px 0;
    float: left;
    border-radius: 7px;
}

.copy-floor-block h6 {
    color: #333;
    margin: 0;
}

.copy-floor-block hr {
    width: 100%;
    margin: 10px 0;
    height: 0.4px;
    background: rgba(0, 0, 0, 0.86);
}

.copy-floor-block button {
    display: block;
    cursor: pointer;
    border-radius: 7px;
    padding: 5px 10px;
    box-sizing: border-box;
    background: #232323;
    color: #eee;
    border: none;
    outline: none;
}

span.more-flats {
    cursor: pointer;
    width: 280px;
    display: flex;
    margin: 20px auto;
    padding: 15px 0;
    justify-content: center;
    align-self: center;
    background: #4079c6;
    color: #fff;
    border-radius: 10px;
}

input.MuiInputBase-input {
    font-size: 17px;
    /*border: 1px solid #bdbdbd;*/
    padding: 10px 15px;
    border-radius: 10px;
}

.block-image-360-with-dottes {
    width: 100%;
    display: table;
    position: relative;
}

.plan-top-right-block {
    display: table;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
}

.plan-top-right-block img {
    max-width: 255px;
    height: auto;
}

.dot-plan-block {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #1171b5;
    z-index: 10;
    position: absolute;
    margin-left: -7px;
    margin-top: -7px;
}

.block-list-item-block > div span {
    margin-right: 0px;
}

.block-list-item span.edit-block-button {
    display: block;
    padding: 2px 4px 0;
    cursor: pointer;
}

.block-list-item-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: ce;
}

.block-list-item-flex-button-control {
    display: flex;
    flex-direction: row;
}

.block-list-item > div.block-list-item-flex-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.block-list-item > div.block-list-item-flex-form p {
    color: #aeaeae;
}

.block-list-item-flex-form input {
    width: 100%;
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 5px;
    border-radius: 4px;
    outline: none;
    padding: 0 10px;
}

.block-list-item-flex-form input[type='submit'] {
    background: #1977f3;
    color: #fff;
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    height: 43px;
}

.block-item-flat-button-redirect {
    margin: 0;
    cursor: pointer;
}

.block-item-flat-button-redirect-rel {
    position: relative;
    left: -11px;
    z-index: 111;
}

.modal_redirect_url {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;

    transition: 0.2s all;
}

.modal_redirect_url_opened {
    opacity: 1;
    visibility: visible;

    transition: 0.2s all;
}

.modal_redirect_url_content {
    width: 50%;
    padding: 20px;
    background: #fff;
    border-radius: 7px;
    position: relative;
}

.block-list-item-flex-form input.block-list-item-flex-form-save {
    width: 170px;
}

.redirect_url_input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.redirect_url_input b {
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 6px 5px;
    position: relative;
    top: -3px;
    border-right: none;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.redirect_url_input input {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.button-icon-remove-flat-save-area-points {
    background: #77d610;
    border-radius: 4px;
    color: #fff;
    padding: 5px;
    text-shadow: 3px 1px 1px rgb(0 0 0 / 20%);
}

.page-editor-block {
    width: 100%;
    display: table;
    box-sizing: border-box;
    padding: 0 15px 10px 0;
}

.page-editor-block input.form-page-input {
    width: 100%;
    margin-bottom: 5px;
}

.meta-seo-page-block {
    display: table;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
    background: #fff;
    margin: 10px 0;
}

.meta-seo-page-block p {
    margin: 5px 0 0 0;
    color: #c3c3c3;
}

.page-editor-block input[type='submit'] {
    width: 170px;
    float: right;
    background: #1977f3;
    outline: none;
    color: #fff;
    border: none;
    font-size: 17px;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px #888;
}

.item-page {
    width: 100%;
    min-height: 285px;
    margin: 0 0 5px 0;
    border-radius: 8px;
    overflow: hidden;
    background: whitesmoke;
    box-shadow: 1px 5px 14px rgb(0 0 0 / 17%);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item-page h1 {
    padding: 10px;
    font-size: 17px;
    background: rgb(255 255 255 / 75%);
    margin: 0;
}

.bottom-page-controll-block {
    box-sizing: border-box;
    padding: 10px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modal_redirect_url_content_close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 555;
}

.modal_redirect_url input {
    width: 100%;
    height: 30px;
    outline: none;
    border: 1px solid #333;
    border-left: none;
}

.modal_redirect_url b {
    padding: 4px 5px;
    margin-top: 7px;
    border: 1px solid #333;
    border-right: none;
}

.modal_redirect_url input[type='submit'] {
    width: 200px;
    margin-top: 7px;
    border: none;
    outline: none;
}

.bottom-page-controll-block > span {
    width: 100%;
}

.bottom-page-controll-block p {
    margin: 0;
}

.bottoms-page-controll-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.bottom-page-controll-block-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: block;
}

.bottom-page-controll-block-icon-remove {
    color: red;
}

.logo-builder-preview {
    width: 180px;
    height: 180px;
}

textarea.textarea-desc-builder {
    padding: 10px;
    min-height: 70px;
    margin-bottom: 5px;
    outline: none;
    max-width: 70%;
}

.seo-block-form input {
    width: 100%;
}

.seo-block-form p {
    margin: 5px 0 0 0;
    color: #b7b7b7;
}

.upload-logo-builder-block {
    width: 100%;
    margin: 20px 0 5px 0;
    box-sizing: border-box;
    padding: 15px;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.block-head-page-360 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.block-head-page-360 input {
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background: #1977f3;
    color: #fff;
    letter-spacing: 0.3px;
    text-shadow: 1px 2px rgb(51 51 51 / 62%);
    display: block;
    border-radius: 5px;
}

.library-component {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.76);
    z-index: 1101;
    opacity: 0;
    visibility: hidden;
    left: 0;
    top: -100%;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.library-component-open {
    top: 0;
    opacity: 1;
    visibility: visible;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.library-component-content {
    width: 80%;
    max-width: 1200px;
    height: 70vh;
    background: #fff;
    border-radius: 7px;
    padding: 15px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
}

.library-component-content-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.library-component-content-head h2 {
    margin: 0;
    font-size: 22px;
}

.library-component-content-head span {
    display: block;
    padding: 6px 6px;
    cursor: pointer;
    background: #bb2727;
    color: #fff;
    border-radius: 5px;
    font-size: 11px;
    letter-spacing: 0.4px;
}

.library-component-content-body {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.library-component-content-body-tabs {
    width: 100%;
    display: flex;
    height: 70px;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    position: sticky;
    top: 50px;
    z-index: 2999;
}

.library-component-content-body-tabs span {
    display: block;
    padding: 10px 20px;
    cursor: pointer;
    background: #2f5f9f;
    font-size: 15px;
    opacity: 1;
    color: rgba(255, 255, 255, 0.7);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.library-component-content-body-tabs span:hover {
    background: #4079c6;
    opacity: 0.87;
    color: #fff;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.library-component-content-body-tabs span.select-tab-products {
    background: #4079c6;
    opacity: 1;
    color: #fff;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.library-component-content-body-items {
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.library-component-block {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.library-component-block-top {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}

.library-component-block-bottom {
    width: 100%;
    height: calc(100% - 40px);
    background: whitesmoke;
    padding: 15px;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.library-component-block-bottom-grid {
    width: 100%;
    margin-top: 0;
    padding: 10px 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

.library-component-block-bottom-grid-products {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.product-item-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 0 40px #d9d9d9;
}
.loadedContent {
    width: 100%;
    height: 100%;
    position: relative;
}

.library-component-block-top input {
    padding: 7px 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background: #4079c6;
    color: #fff;
    letter-spacing: 0.3px;
    text-shadow: 1px 2px rgb(51 51 51 / 62%);
    display: block;
    border-radius: 5px;
}

.form-create-categories {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    left: 0;
    background: #fff;
    z-index: 6666;
    opacity: 0;
    visibility: hidden;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.form-create-categories-open {
    min-height: 100%;
    top: 0;
    opacity: 1;
    visibility: visible;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.form-create-categories-top {
    display: flex;
    width: 100%;
    height: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c7c7c7;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.form-create-categories-top h3 {
    color: rgba(0, 0, 0, 0.57);
}

.form-create-categories-top span {
    display: block;
    padding: 6px 6px;
    cursor: pointer;
    background: #4079c6;
    color: #fff;
    border-radius: 5px;
    font-size: 11px;
    letter-spacing: 0.4px;
}

.form-create-categories-top h3 {
    margin: 0;
    font-size: 15px;
}

.form-create-categories-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-create-categories-bottom section {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0;
}

.form-create-categories-bottom section input[type='text'],
.form-create-categories-bottom section input[type='number'],
.form-create-categories-bottom section input {
    width: 50%;
    height: 51px;
    font-size: 18px;
    padding: 0 15px;
    outline: none;
    border: none;
    background: #f1f1f1;
    border-radius: 4px;
    font-weight: normal;
}

.form-create-categories-bottom section input[type='file'] {
    font-size: 14px;
    height: 25px;
    padding: 0;
    background: transparent;
}

input.form-create-category-button {
    width: 170px;
    height: 45px;
    outline: none;
    border: none;
    background: #4079c6;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 15px;
}

.icon-preview-category {
    width: 200px;
    height: 200px;
    margin: 10px 0 0;
}

.ant-picker-dropdown {
    z-index: 105336;
}

.category-item-block {
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    background: #fff;
    padding: 23px 0 0 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    transition: 0.3s all;
}

.category-item-block:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.32);
    transition: 0.3s all;
}

.product-item-block {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 5px;
    padding: 25px 0 0 0;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.product-item-block-overlay {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product-item-block-icon-overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-item-block-controll {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(255, 255, 255, 0.93);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.product-item-block-controll {
    z-index: 1002;
}

.product-item-block-controll-logo-shop {
    position: absolute;
    width: 55px;
    height: 50px;
    border-radius: 50%;
    bottom: 20px;
    right: 20px;
    opacity: 0.67;

    transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.product-item-block-controll-logo-shop:hover {
    opacity: 1;

    transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -o-transform: scale(1.3);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.category-item-block-controll span {
    width: 25px;
    height: 25px;
    border-radius: 8px;
    margin: 0 11px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-item-block:hover .category-item-block-controll {
    top: 0;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.category-item-block-icon {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 0 23px rgba(0, 0, 0, 0.22);
}

.category-item-block p {
    font-weight: bold;
    font-size: 13px;
    margin: 15px 0 10px 0;
    text-align: center;
    line-height: 16px;
    color: #464646;
    padding: 5px 7px;
    border-radius: 5px;
}

.category-item-block h6 {
    font-weight: normal;
    font-size: 13px;
    margin: 0 0 9px 0;
    text-align: center;
    line-height: 13px;
    color: #afafaf;
}

.page-block-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
}

.page-block-header span {
    display: block;
    border-radius: 7px;
    background: #1977f3;
    color: #fff;
    padding: 7px 13px;
    cursor: pointer;
}

.modal-setting-for-pages {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: rgb(255 255 255 / 82%);
    z-index: 1102;
    opacity: 0;
    visibility: hidden;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.modal-setting-for-pages-open {
    opacity: 1;
    visibility: visible;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.modal-setting-for-pages-content {
    width: 55%;
    max-height: 70vh;
    overflow-y: auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 33px rgba(0, 0, 0, 0.1);
}

.modal-setting-for-pages-content-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-setting-for-pages-content-head-icon-close {
    color: #333;
}

.modal-setting-for-pages-content-head span {
    cursor: pointer;
    background: #f3eded;
    border-radius: 5px;
    display: flex;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
}

.modal-setting-for-pages-content-head h5 {
    display: table;
    padding: 10px 34px;
    color: #fff;
    background: #2266bf;
    border-radius: 5px;
}

.modal-setting-for-pages-content-body-section-head h4 {
    color: rgb(0 0 0 / 35%);
    font-size: 14px;
    border-bottom: 1px dashed rgb(0 0 0 / 19%);
    display: block;
    padding-bottom: 7px;
}

.modal-setting-for-pages-content-body {
    display: table;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
}

.modal-setting-for-pages-content-body section {
    width: 100%;
    background: #fbfbfb;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px;
    margin: 15px 0;
    box-shadow: 0 0 20px rgb(51 51 51 / 12%);
}

.modal-setting-for-pages-content-body-section-inputs p {
    margin: 0 0 2px 0;
}

.modal-setting-for-pages-content-body-section-inputs input {
    margin: 0 0 10px 0;
    width: 100%;
    height: 35px;
    outline: none;
    border: none;
    background: #fff;
    padding: 0 10px;
    border-radius: 5px;
}

.modal-setting-for-pages-content-body-section-input-save {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modal-setting-for-pages-content-body-section-input-save input {
    display: block;
    border-radius: 7px;
    background: #1977f3;
    color: #fff;
    padding: 7px 13px;
    cursor: pointer;
    width: auto;
    margin-top: 15px;
}

.form-create-categories-bottom section select {
    width: 50%;
    height: 45px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: bold;
    padding: 0 15px;
}

.product-item-block-icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    opacity: 0.055;
}

.category-block-360-component {
    position: fixed;
    max-width: 300px;
    height: 100vh;
    min-width: 255px;
    z-index: 2000;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 43px rgba(0, 0, 0, 0.2);
    top: 0;
    right: -100%;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.category-block-360-component-open {
    max-width: 300px;
    right: 0px;
    opacity: 1;
    z-index: 999999;
    visibility: visible;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.category-block-360-component-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.category-block-360-component-item {
    width: auto;
    border: 1px solid rgb(190, 205, 226);
    background: #f9fafb;
    border-radius: 8px;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.category-block-360-component-item:hover {
    background: #f9fafb;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.29);
    border: 1px solid rgba(51, 51, 51, 0.08);

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.category-block-360-component h1 {
    padding: 20px 20px 0 20px;
    margin: 0 0 0 10px;
    font-size: 20px;
}

.category-block-360-component-item-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 10px 3%;
}

.category-block-360-component-item p {
    font-weight: bold;
    font-size: 11px;
    text-align: center;
}

.product-block-360-component {
    z-index: 999999999;
}

.category-block-360-component-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #fff;
}

.category-block-360-component-head h1 {
    padding: 0;
    font-size: 14px;
    font-weight: bold;
}

.parent-hotspot-block {
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 7px;
    box-sizing: border-box;
    padding: 10px;
    position: absolute;
    left: -129px;
    bottom: -6px;
    margin-bottom: 19px;
    z-index: 10000;

    opacity: 0;
    visibility: hidden;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.parent-hotspot-block:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 17px solid #2e2c28;
    position: absolute;
    bottom: -16px;
}

.parent-hotspot-block:before {
    content: '';
    display: block;
    width: 100%;
    height: 40px;
    background: transparent;
    top: 100%;
    position: absolute;
}

.parent-hotspot-block a {
    width: 100%;
    height: 30px;
    background: #31bced;
    border-radius: 7px;
    position: absolute;
    top: -33px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
}

.parent-hotspot-block a:after {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    background: transparent;
    position: absolute;
    top: 100%;
}

.parent-hotspot-block p {
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
}

.parent-hotspot-block h1 {
    font-size: 19px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    margin: 6px 0 2px;
}

.pnlm-pointer:hover .parent-hotspot-block {
    margin-bottom: 49px;
    z-index: 10000000;

    opacity: 1;
    visibility: visible;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.pnlm-container {
    background: #fff !important;
}

.pnlm-load-box {
    background-color: red !important;
    opacity: 0;
}

.parent-hotspot-logo-shop {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: -55px;
}

.parent-hotspot-remove {
    width: 35px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: -37px;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    align-items: center;
    background: #fff url(../../assets/icons/delete.png) center / 45% no-repeat;
}

.sub-categories-block {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 4000;
    padding: 15px;
    background: #fff;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.sub-categories-block-opened {
    opacity: 1;
    visibility: visible;
    top: 0;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.head-sub-setting-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.head-sub-setting-block h5 {
    margin: 0;
    margin-right: 10px;
}

.head-sub-setting-block-parent select {
    margin-top: 15px;
}

h5.text-not-fount-library {
    display: flex;
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.block-item-flat-buttons-controll-section {
    width: 100%;
    background: #f7f7f7;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.template-icon-controll,
.template-icon-controll a {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.template-icon-controll a.link-button-360 {
    width: 38px;
    height: 100%;
}

.select-categories-block-form-product {
    display: flex;
    flex-direction: column;
}

.switch-block-free-download {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fffcda;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    border: 1px dashed rgb(0 0 0 / 21%);
}

.switch-block-free-download p {
    margin: 0;
    margin-right: 10px;
}

.switch-block-free-download label {
    margin-bottom: 0px;
}

.active-link-sidebar {
    background: #f9f9f9;
    position: relative;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.active-link-sidebar:after {
    content: '';
    position: absolute;
    width: 20px;
    top: 0;
    left: -20px;
    background: #f9f9f8;
    display: block;
    height: 100%;
    border-left: 3px solid #6199f6;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.active-link-sidebar:before {
    content: '';
    position: absolute;
    width: 20px;
    top: 0;
    right: -20px;
    background: #f9f9f8;
    display: block;
    height: 100%;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.block-item-flat-buttons-left {
    display: flex;
    flex-direction: row;
}

.block-item-flat-buttons-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.block-item-flat-buttons-right p {
    font-size: 12px;
    margin: 0;
    margin-right: 5px;
    color: rgb(0 0 0 / 29%);
}

.block-item-flat-buttons-right label {
    margin-bottom: 0px;
}

.edit-button-main {
    width: 230px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    border-radius: 4px;
    background: rgb(25 119 243);
    color: #fff;
    float: right;
    font-size: 17px;
    margin-bottom: 20px;
}

.price-apartment-block-form-block {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 0px 0px 15px 0;
}

.tabs-form-manual-or-auto {
    position: relative;
    display: table;
    margin-bottom: 15px;
    background: #99a0a8;
    overflow: hidden;
    border-radius: 5px;
}

.tabs-form-manual-or-auto span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 240px;
    padding: 15px 25px;
    font-size: 12px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
}

.tabs-form-manual-or-auto-overlay {
    width: calc(50% + 0px);
    height: 100%;
    background: #4079c6;
    position: absolute;
    border-radius: 5px;
    top: 0;
    left: 0;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}

.tabs-form-manual-or-auto-body {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 11;
}

.tabs-form-manual-or-auto-overlay-left {
    left: 50%;

    transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}
.parent-block-menu {
    background-color: #fff;
}
.ant-layout-content {
    min-height: 93vh;
}

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
    background-color: #fff;
}

.ant-layout-sider {
    z-index: 99999;
    background: #fff;
    height: 100%;
}

.ant-layout-header {
    padding: 0 10px;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 999999;
}

.ant-layout-sider-children {
    overflow: auto;
}

.prices-form-apartment-complex {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.ant-layout-sider-trigger {
    background: #4079c6;
}

.ant-layout {
    background-color: #fff;
}

.prices-form-apartment-complex-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.prices-form-apartment-complex-item p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 0 5px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(0 0 0 / 16%);
    color: rgb(0 0 0 / 38%);
}

.prices-form-apartment-complex-item input {
    border: none;
    text-align: center;
    font-weight: bold;
    color: #333;
    height: 30px;
    outline: none;
}

.prices-form-apartment-complex-button-body {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.prices-form-apartment-complex-button-body input {
    padding: 14px 24px;
    background: #4079c6;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    border-radius: 10px;
}

.prices-form-apartment-complex-button-body-warning input {
    background: #d25858;
}

.analytic-block-filter-head {
    width: 100%;
    margin: 20px 0;
    background: #fffffe;
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    padding: 10px;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.analytic-block-filter-item {
}

.analytic-block-filter-item select {
    width: 180px;
    height: 35px;
    border: none;
    outline: none;
    background: #f9f9f8;
    margin-right: 10px;
    border-radius: 7px;
    color: #a5a5a5;
}

.analytic-block-filter-item select option {
    font-size: 14px;
}

.analytic-block-filter-body {
    width: 100%;
}

.analytic-block-filter-not {
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.analytic-block-filter-not-icon {
    color: #ececec;
}

.analytic-block-filter-not p {
    color: #d0d0d0;
    font-weight: bold;
    font-size: 18px;
}

.analytic-block-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.analytic-block-item {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.analytic-block-item {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.analytic-block-item span {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #6199f6;
    color: #e6e6e6;
    font-size: 13px;
    box-sizing: border-box;
    padding: 10px 15px;
    text-align: center;
}

.analytic-block-item span:nth-child(1),
.analytic-block-item span:nth-child(2),
.analytic-block-item span:nth-child(3),
.analytic-block-item span:nth-child(4) {
    border-right: 1px solid rgb(255 255 255 / 18%);
}

.analytic-block-item span:nth-child(3) {
    width: 200px;
}

.analytic-block-filter-item .button-sort {
    cursor: pointer;
    margin-right: 7px;
}

.analytic-block-item-body:nth-child(odd) span {
    background: #85b1fb;
}

.analytic-block-item-body:nth-child(even) span {
    background: #7aa3e8;
}

.analytic-block-item-head {
    box-shadow: 0 0 20px rgb(0 0 0 / 40%);
    position: relative;
    z-index: 11;
}

.input-section-form-switch {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 0 10px rgb(0 0 0 / 7%);
    border: 1px solid #d4d4d4;
}

.input-section-form-switch p {
    margin: 0;
}

.analytic-block-filter-counts {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.analytic-block-filter-counts-item {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background: #85b1fb;
    border-radius: 10px;
}

.analytic-block-filter-counts-item h2 {
    margin: 0;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
}

.analytic-block-filter-counts-item h3 {
    margin: 10px 0 0 0;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
    padding: 13px 21px;
    background: #6199f6;
    border-radius: 7px;
    font-weight: bold;
}

.check-files-block p {
    word-break: break-all;
}

.icons-settings-apart-complex-block {
    display: flex;
    flex-direction: row;
}

.main-section-grid-chess {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
}

.list_products_block {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.item_product {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 3px #00000008;
    border: 1px solid #0000001f;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
}

.item_product_photo {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
    border-radius: 50%;
    box-shadow: 0 0 47px rgb(0 0 0 / 8%);
}

.item_product p {
    margin: 0;
    color: #33333396;
    font-weight: bold;
    word-break: break-all;
    font-size: 12px;
}

.no-apart-data-content {
    width: 100%;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3e4c69;
    border-radius: 5px;
    margin: 15px 0;
    box-sizing: border-box;
}

.no-apart-data-content h5 {
    margin: 0;
    font-size: 17px;
    text-transform: uppercase;
    color: #c1c1c1;
}
