.topLinks {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	a {
		display: block;
		width: 100%;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #1977f3;
		color: #fff;
		font-size: 16px;
		margin: 0;

		transition: .2s all;

		&:hover {
			background: #2474dd;

			transition: .2s all;
		}
	}

	.activeLink {
		background: #2474dd;
	}

}

.headBlock {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	.addBank {
		cursor: pointer;
		width: 20px;
		height: 20px;
		background: #1977f3;
		color: #fff;
		border-radius: 50%;
	}
}

.listBanks {
	width: 100%;
	min-height: 500px;

	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}

.loading {
	width: 100%;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.addBankModal {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 1000;
	background: rgba(255,255,255,0.8);
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;

	transition: .3s all;

	.formContent {
		width: 600px;
		box-sizing: border-box;
		padding: 15px;
		border-radius: 5px;
		box-shadow:  0 0 20px rgba(0,0,0,0.17);

		h3 {
			font-size: 18px;
			margin: 0 0 10px 0;
			color: #888;
		}

		input.addButtonBank {
			width: 200px;
			height: 35px;
			cursor: pointer;
			border: none;
			outline: none;
		    background: #2164bd;
		    color: #fff;
		    border-radius: 5px;
		    float: right;
		}

		section {
			width: 100%;
			margin-bottom: 10px;

			p {
				margin: 0;
				color: rgba(0,0,0,0.4);
			}

			img {
				width: 200px;
				height: auto;
				margin-top: 10px;
				border-radius: 10px;
			}

			input[type="text"] {
				width: 100%;
				height: 35px;
				padding: 0 15px;
				outline: none;
				border: 1px solid rgba(0,0,0,0.2);
				border-radius: 4px;
			}
		}
	}
}

.addBankModalOpened {
	opacity: 1;
	visibility: visible;

	transition: .3s all;
}

.itemBank {
	width: 100%;
	margin-bottom: 10px;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,0.18);
	box-sizing: border-box;
	padding: 10px;
	display: flex;
	align-items: center;
	position: relative;
	overflow: hidden;

	&:hover span.DeleteBank {
		right: 10px;

		transition: .2s all;

	}

	span.DeleteBank {
		position: absolute;
		width: 35px;
		height: 35px;
		display: flex;
		cursor: pointer;
		top: 10px;
		right: -50px;
		z-index: 100;
		background: #cd2020;
		border-radius: 50%;
		align-items: center;
		justify-content: center;

		transition: .2s all;

	}

	.itemBankLeft {
		width: 100px;
		height: auto;
		margin-right: 15px;
	}

	.itemBankRight {
		
		p {
			margin: 0px;
		    line-height: 15px;
		    font-size: 11px;
		}
	}
}