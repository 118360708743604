.mainBlock {
    width: 100%;
    min-height: 90vh;
    margin-bottom: 20px;

    .buttonMore {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0;
    }

    .content {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    .pageCategories {
        width: 100%;
        margin: 10px 0;

        .button {
            margin: 0 0 10px 0;
        }

        .sectionForm {
            width: 100%;
            margin: 5px 0;

            .optionForm {
                width: 100%;
                box-sizing: border-box;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid #d8d8d8;
                background-color: #fff;

                .imageBlock {
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    margin: 15px 0;
                }

                .button {
                    margin: 0;
                    position: relative;

                    input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 11;
                        opacity: 0;
                    }
                }

                .optionFormSwitch {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .optionFormSelect {
                    width: 100%;
                    display: flex;
                    margin: 7px 0 0 0;

                    .formSectionSelect {
                        width: 100%;
                    }
                }

                p {
                    margin: 0 7px 0 0;
                }
            }
        }
    }

    .filter {
        width: 100%;
        margin: 10px 0;
        position: sticky;
        top: 65px;
        z-index: 101;
        display: flex;
        flex-direction: row;
        gap: 15px;

        .select {
            width: 330px;
        }
    }

    .contentWrapper {
        width: 100%;

        .extraBlock {
            width: 100%;
            min-height: 550px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .content {
            width: 100%;
            margin: 15px 0;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;
        }
    }

    .container {
        width: 100%;

        p {
            margin: 0;
        }

        .blockAwait {
            width: 100%;
            height: 450px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .blockAwaitIcon {
                font-size: 33px;
                margin: 15px;
                color: rgba(0, 0, 0, 0.2);
            }

            h5 {
                font-size: 14px;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.34);
            }
        }

        .list {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;
            margin: 25px 0;

            .item {
                width: 100%;
                padding: 10px;
                background-color: #fff;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                position: relative;

                .DropdownItem {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }

                h4 {
                    font-weight: normal;
                    font-size: 15px;
                }

                .shops {
                    width: 100%;
                    position: relative;
                    height: 35px;

                    .shop {
                        width: 35px;
                        height: 35px;
                        position: absolute;
                        border-radius: 50%;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.33);
                    }
                }
            }
        }
    }

    .contentCriteria {
        width: 100%;

        .defaultBlock {
            width: 100%;
            height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .defaultBlockLoader {
                color: rgba(0, 0, 0, 0.3);
                font-size: 33px;
            }
        }

        .itemCriteria {
            width: 100%;
            padding: 10px;
            margin: 5px 0;
            border-radius: 5px;
            background-color: #ffffff;
            border: 1px solid #ebebeb;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 19px;
            }

            .itemCriteriaButtons {
                display: flex;
                flex-direction: row;
                align-items: center;

                .itemCriteriaButton {
                    font-size: 13px;
                    cursor: pointer;
                }
            }
        }
    }

    .contentCategories {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 15px 0;

        .headerRight {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;

            a {
                text-align: center;
            }
        }

        span {
            cursor: pointer;
            background-color: #4079c6;
            border-radius: 5px;
            padding: 10px 15px;
            color: #fff;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        a {
            cursor: pointer;
            color: #fff;
            background: #4079c6;
            padding: 10px 15px;
            border-radius: 5px;
        }

        h2 {
            color: #4e719e;
            font-weight: bold;
            font-size: 18px;
            margin: 0px;
        }
    }

    .formBlock {
        width: 100%;
        margin: 15px 0;

        .formSection {
            width: 100%;
            margin: 5px 0;

            p {
                margin: 0px;
            }

            .formSectionRows {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 10px;

                .button {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 10px;
                    cursor: pointer;
                    position: relative;

                    .colorsBlock {
                        width: 100%;
                        background-color: #f6f6f6;
                        top: 100%;
                        left: 0px;
                        position: absolute;
                        box-shadow: 0 0 20px #d2d2d2;
                        z-index: 111;
                        opacity: 0;
                        visibility: hidden;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;

                        transition: 0.3s all;

                        div {
                            width: 100%;
                            height: 70px;
                        }

                        .color1 {
                            background-color: rgb(255, 255, 255);
                        }
                        .color2 {
                            background-color: rgb(233, 214, 190);
                        }
                        .color3 {
                            background-color: rgb(254, 203, 218);
                        }
                        .color4 {
                            background-color: rgb(102, 197, 253);
                        }
                        .color5 {
                            background-color: rgb(128, 128, 128);
                        }
                        .color6 {
                            background-color: rgb(254, 211, 48);
                        }
                        .color7 {
                            background-color: rgb(194, 24, 168);
                        }
                        .color8 {
                            background-color: rgb(60, 212, 198);
                        }
                        .color9 {
                            background-color: rgb(0, 0, 0);
                        }
                        .color10 {
                            background-color: rgb(253, 144, 38);
                        }
                        .color11 {
                            background-color: rgb(90, 19, 154);
                        }
                        .color12 {
                            background-color: rgb(125, 229, 45);
                        }
                        .color13 {
                            background-color: rgb(119, 60, 11);
                        }
                        .color14 {
                            background-color: rgb(190, 7, 18);
                        }
                        .color15 {
                            background-color: rgb(42, 86, 215);
                        }
                        .color16 {
                            background-color: rgb(53, 101, 13);
                        }
                    }

                    .colorsBlockOpened {
                        top: 0%;
                        opacity: 1;
                        visibility: visible;

                        transition: 0.3s all;
                    }
                }
            }

            .formSectionSelect {
                width: 100%;
            }

            .formSectionMaskInput {
                width: 100%;
                outline: none;
                height: 40px;
                border: 1px solid #e1e1e1;
                padding: 0 10px;
            }

            .formSectionUploadFile {
                width: 100%;
                background: #fff;
                border-radius: 4px;
                padding: 10px;
                box-sizing: border-box;

                h3 {
                    margin: 0 0 5px 0;
                    font-size: 15px;
                    font-weight: normal;
                    color: #b8b8b8;
                }

                .photosProduct {
                    display: grid;
                    width: 100%;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    grid-gap: 10px;

                    .photoProduct {
                        width: 100%;
                        height: 200px;
                        border-radius: 6px;
                        border: 3px solid #e7e7e7;
                        position: relative;

                        div {
                            cursor: pointer;
                            width: 100%;
                            padding: 10px;
                            font-size: 12px;
                            background: #fff;
                            top: 0;
                            left: 0;
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 6px;
                        }

                        div.MainPhoto {
                            background: orange;
                        }

                        span.deletePhoto {
                            position: absolute;
                            bottom: 0px;
                            right: 0px;
                            z-index: 100;
                            cursor: pointer;
                            color: red;
                            width: 35px;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                .formSectionFile {
                    display: flex;
                    cursor: pointer;
                    margin: 4px 0;
                    position: relative;
                    width: fit-content;

                    span {
                        color: #fff;
                    }

                    input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 11;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
            }
        }

        .formSectionOther {
            width: 100%;
            padding: 10px;
            background: #fff;
            border-radius: 5px;
            box-sizing: border-box;
            border: 1px solid #dedede;

            p {
                margin-bottom: 10px;
            }

            h5 {
                margin: 10px 0 0;
            }

            .paramentItem {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;
                margin: 5px 0;
                background: #eee;
                width: 350px;
                position: relative;

                .paramentItemDelete {
                    position: absolute;
                    right: -20px;
                    color: #de2828;
                    font-size: 17px;
                    cursor: pointer;
                }

                h6 {
                    font-size: 15px;
                    margin: 0;
                    font-weight: normal;
                    color: #797979;
                }
                b {
                    width: 140px;
                    margin: 0;
                    font-size: 15px;
                }
                span {
                    margin: 0;
                }
            }
        }
    }
}

.Dropdown {
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 101;
}

.itemCategory {
    width: 100%;
    background: #fff;
    margin: 4px 0;
    border-radius: 5px;

    .itemCategoryMain {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 3px 10px;

        .itemCategoryMainLeft {
            display: flex;
            flex-direction: row;
            align-items: center;

            .noIcon {
                background: #1890ff;
                display: flex;
                color: #c3ddff;
                font-size: 10px;
                justify-content: center;
                align-items: center;
            }
        }

        .itemCategoryMainRight {
            width: fit-content;
            display: flex;
            flex-direction: row;
            align-items: center;

            .cat_button {
                cursor: pointer;
                width: 25px;
                height: 25px;
                background: #4079c6;
                color: #fff;
                margin: 0 3px;
                font-size: 13px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
            }
        }

        p {
            margin: 0px;
        }

        .itemCategoryMainLogo {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 0 10px 0 0;
        }
    }

    .itemCategoryMainSub {
        width: auto;
        background: #fff;
        margin: 5px 0;
        border-radius: 5px;
        border: 1px solid #ededed;
        box-shadow: 0px 0px 20px transparent;

        transition: 0.2s all;

        &:hover {
            box-shadow: -15px 0px 20px #dddddd;

            transition: 0.2s all;
        }
    }
}

.loaderBlock {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loaderIcon {
        color: rgba(0, 0, 0, 0.4);
        font-size: 33px;
    }

    h6 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.5);
    }
}

.modalCategory {
    width: 100%;

    .formSectionSelect {
        position: relative;
        z-index: 9999;
    }
}

.zIndex {
    z-index: 9999999;
}

.listShops {
    width: 100%;
    display: flex;
    flex-direction: row;

    .listShopsContent {
        display: flex;
        flex-direction: row;
        position: relative;
        margin: 0 10px;

        .shopItem {
            width: 25px;
            height: 25px;
            top: -1px;
            position: absolute;
            border-radius: 50%;
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
            cursor: pointer;

            transform: scale(1);
            transition: 0.1s all;

            &:hover {
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.36);
                z-index: 100;
                transform: scale(1.3);
                transition: 0.09s all;
            }
        }
    }
}

.popoverContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 9999999999999;

    span {
        margin: 0 0 4px 0;
    }
}

.itemCheck {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #fff;
    margin: 2px 0;
    cursor: pointer;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.3s all;

    &:hover {
        z-index: 100;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
        transition: 0.3s all;
    }

    .textmargin {
        margin: 3px 0;
        color: rgba(0, 0, 0, 0.4);
    }

    .itemCheckLeft {
        width: 98%;
        margin-left: 2%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.inputStyle {
    width: 100%;
}
