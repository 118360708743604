.markersBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 15px 0;

    .markersBlockSection {
        display: flex;
        flex-direction: column;
        width: 100%;

        select {
            height: 40px;
            outline: none;
        }
    }
}

.mainBlock {
    width: 100%;
    position: relative;

    .mainBlockList {
        margin: 15px 0;
        width: 100%;
    }

    > input {
        width: 100%;
        height: 46px;
        padding: 0 15px;
        border: 1.5px solid #4e719e;
        outline: none;
        border-radius: 4px;
        font-size: 18px;
        color: #4e719e;
        font-weight: bold;
    }

    ::placeholder {
        color: #4e719e;
        font-size: 18px;
        font-weight: normal;
    }

    > h1 {
        color: #4e719e;
        font-weight: bold;
        font-size: 18px;
    }

    h1.budget_result_text {
        font-weight: bold;
        font-size: 18px;
        color: #4e719e;
        margin: 30px 0;
    }
}

.mainBlockHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;

    .mainBlockHeadIconBack {
        margin: 0 10px 0 0;
    }
}

.backButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    cursor: pointer;
}

.buttonSubProduct {
    display: flex;
    padding: 5px;
    background: #4e719e;
    cursor: pointer;
    color: #fff;
    justify-content: center;

    &:nth-child(1) {
        margin-bottom: 5px;
    }
}

.list_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.buttonCreateGroup {
    display: flex;
    padding: 8px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    background: #87b925;
    color: #fff;
    width: fit-content;
}

.headPageBudget {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 15px 0;
    position: sticky;
    top: 0px;
    z-index: 111;

    .rightButtonCategories {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        flex-direction: row;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            border-radius: 4px;
            cursor: pointer;
            background: #4079c6;
            color: #fff;
            font-weight: bold;
        }

        .rightButtons {
            width: auto;
            display: flex;
            flex-direction: row;

            span {
                margin-right: 10px;
            }
        }
    }

    h1 {
        color: #4e719e;
        margin: 0;
        font-weight: bold;
        font-size: 18px;
    }

    span {
        display: flex;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        background: #4079c6;
        color: #fff;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;

        a {
            text-decoration: none;
            color: #fff;
        }
    }
}

.blockCreateGroup {
    background: rgb(245, 245, 245);
    box-shadow: 0 0 15px rgba(51, 51, 51, 0.25);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin: 15px 0;
    display: flex;
    flex-direction: row;

    input {
        width: 50%;
        height: 35px;
        font-size: 13px;
        padding: 0 10px;
        border: 1px solid rgba(68, 68, 68, 0.19);
        outline: none;
        border-radius: 5px;
    }
}

.checkBoxesContent {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.sectionListOptions {
    width: 100%;
    background: #eee;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 10px;
}

.formCreateCategory {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 0 0 15px rgba(51, 51, 51, 0.17);

    .sectionFormCat {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .checkBoxInput {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            background: rgb(238, 238, 238);
            box-sizing: border-box;
            padding: 10px;
            border-radius: 5px;

            .checkBoxInputContentSelect {
                margin-left: 10px;
            }
            .checkBoxInputContent {
                display: flex;
                flex-direction: row;
            }

            p {
                margin: 0 0 0 10px;
                padding: 0px;
            }
        }

        span {
            color: rgba(0, 0, 0, 0.38);
            margin: 0 0 5px 0;
        }

        input {
            width: 100%;
            height: 35px;
            outline: none;
            border: 1px solid rgb(57, 94, 144);
            padding: 0 15px;
            border-radius: 5px;
        }

        input[type='checkbox'] {
            width: auto;
            height: auto;
        }

        input[type='submit'] {
            border: none;
            background: #4079c6;
            width: 170px;
            margin: 10px 0 0 0;
            color: #fff;
        }

        .blockSelectCat {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            box-sizing: border-box;
            background: #eee;
            margin-top: 15px;
            display: flex;
            flex-direction: column;

            input.buttonSelectCat {
                background: #4079c6;
                margin: 0;
            }
        }
    }
}

.form_select_cat {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.67);
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all;
}
.form_select_cat_opened {
    opacity: 1;
    visibility: visible;
    transition: 0.2s all;

    .form_select_cat_content {
        width: 55%;
        height: 60vh;
        padding: 15px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 5px;
        position: relative;
    }

    .form_select_cat_close_icon {
        position: absolute;
        top: -40px;
        right: 0;
        cursor: pointer;
    }
}

.lineItemCat {
    width: 50%;
    display: flex;
    height: 4px;
    margin: 5px 5px 5px 5px;
    transition: 0.4s all;
}

.itemCategory {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    background: #eee;
    margin-bottom: 10px;
    background: #f3f3f3;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: 0.2s all;

    .itemCategoryChildItem {
        width: 100%;
        margin-top: 5px;
        box-sizing: border-box;
        padding: 0 10px;
        border-radius: 10px;
        background: #eee;
        margin-bottom: 5px;
        background: #f3f3f3;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        transition: 0.2s all;

        &:hover {
            cursor: pointer;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            transition: 0.2s all;
        }
    }

    &:hover {
        cursor: pointer;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        transition: 0.2s all;
    }

    .itemCategoryChild {
        display: flex;
        flex-direction: column;
        width: 100%;

        .itemCategoryChildHead {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            padding: 10px 0;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            transition: 0.2s all;

            &:hover {
                svg {
                    opacity: 1;
                    transition: 0.2s all;
                }
            }

            .buttonsOfCategory {
                display: flex;
                flex-direction: row;

                .buttonsOfCategoryEdit {
                    margin-right: 10px;
                }
            }

            svg {
                opacity: 0;
                transition: 0.2s all;
            }

            b {
                font-weight: bold;
                border-radius: 4px;
                margin-left: 7px;
                color: rgba(147, 147, 147, 0.7);
                display: flex;
                font-size: 11px;
                padding: 2px 2px 2px;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(147, 147, 147, 0.7);
            }

            > h4 {
                margin: 0;
                font-size: 14px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: row;
                align-items: center;

                small {
                    display: flex;
                    margin-left: 0px;
                    padding: 5px 8px;
                    position: relative;
                    background: #d39a19;
                    letter-spacing: 0.9px;
                    color: #fff;
                    font-size: 7px;
                    border-radius: 3px;
                    text-shadow: 1px 1px 2px #4c4c4c;
                    text-transform: uppercase;
                    left: 1px;
                    top: 0.5px;
                }

                .curcleOneStep {
                    width: 19px;
                    height: 19px;
                    background: #65a73b;
                    border-radius: 11px;
                    margin-left: 7px;
                    display: flex;
                    color: #fff;
                    font-size: 9px;
                    justify-content: center;
                    align-items: center;
                }

                .curcleTwoStep {
                    width: 19px;
                    height: 19px;
                    border-radius: 11px;
                    margin-left: 7px;
                    display: flex;
                    color: #fff;
                    font-size: 9px;
                    justify-content: center;
                    align-items: center;
                    background: #3d6b93;
                }

                .curcleThreeStep {
                    width: 19px;
                    height: 19px;
                    border-radius: 11px;
                    margin-left: 7px;
                    right: 7px;
                    display: flex;
                    color: #fff;
                    font-size: 9px;
                    justify-content: center;
                    align-items: center;
                    background: #b820a5;
                }

                .curcleFourStep {
                    width: 19px;
                    height: 19px;
                    border-radius: 11px;
                    margin-left: 7px;
                    display: flex;
                    color: #fff;
                    font-size: 9px;
                    justify-content: center;
                    align-items: center;
                    background: #e1a51e;
                }

                .curcleFiveStep {
                    width: 19px;
                    height: 19px;
                    border-radius: 11px;
                    margin-left: 7px;
                    display: flex;
                    color: #fff;
                    font-size: 9px;
                    justify-content: center;
                    align-items: center;
                    background: #f14512;
                }
            }
        }
    }
}

.listCategories {
    width: 100%;
    margin-top: 15px;
}

.defaultBlockCenter {
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    border-radius: 5px;
    margin-top: 10px;

    h2 {
        text-transform: uppercase;
        margin: 0;
        font-size: 17px;
    }
}

.itemCatsFirst {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .itemCatsFirstTop {
        width: 100%;
        border-radius: 8px;
        background: #dddddd;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        padding: 5px 15px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    h5 {
        color: #4e719e;
        font-size: 18px;
    }

    .itemCatsFirstLeft {
        display: flex;
        flex-direction: row;
        justify-content: space-berween;
        align-items: center;

        .itemCatsFirstLeftIcon {
            margin-right: 10px;
            color: #4e719e;
        }
    }
}

.itemCatsFirstHide {
    opacity: 0;
    visibility: hidden;
    height: 0px;
    margin: 0px;
}

.itemCatsTwo {
    margin-top: 0;
    margin-bottom: 0;
    height: 0px;
    overflow: hidden;
    position: relative;
    transition: 0.2s all;

    .itemCatsFirstTop {
        background: #ebebeb;
    }
}

.itemCatsTwoOpened {
    margin-top: 15px;
    height: auto;
    overflow: visible;
    transition: 0.2s all;
}
.itemCatsSecondTop {
    margin-bottom: 15px;
}

.saveTemplateButton {
    cursor: pointer;
    width: 200px;
    margin-top: 15px;
    height: 35px;
    background: #5bbf10;
    border-radius: 5px;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableProducts {
    width: 100%;
    margin-left: 0%;
    display: flex;
    opacity: 1;
    flex-direction: column;
    transition: 0.3s all;

    table {
        border: 1px solid #1f4b84;
        margin-bottom: 10px;

        .trHead {
            border: 1px solid rgba(31, 75, 132, 0.25);
        }
        .trBody {
            margin: 10px 0;
            position: relative;
        }

        .overlayTableBlock {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: red;
        }
    }

    tr th {
        text-align: center;
        font-size: 12px;
        box-sizing: border-box;
        padding: 3px 10px;
        color: rgba(0, 0, 0, 0.3);
    }

    tr td {
        text-align: center;
        font-size: 12px;
        box-sizing: border-box;
        padding: 10px;
    }

    tr td:nth-child(3) {
        background: #4e719e;
        color: #fff;
    }

    .contentTable {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 10px;
            border: 1px solid #c6d4e7;
            border-right: none;
            text-align: center;
        }

        span:nth-last-child(1) {
            border-right: 1px solid #c6d4e7;
        }
    }
}

.otherProductButton {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background: #4e719e;
    align-items: center;
    margin: 2px 0;

    h5 {
        color: #fff;
        margin: 0px;
        margin-left: 20px;
        font-size: 12px;
        font-weight: bold;
    }

    .otherProductButtonIcon {
        color: #fff;
        position: relative;
        margin-left: 20px;
        color: #fff;
    }
}

.otherProductContent {
    width: 100%;
    display: flex;
    opacity: 0;
    flex-direction: column;
    visibility: hidden;
    position: relative;
    left: 0;
    z-index: 1111;
    top: -10px;
    height: 0px;

    transition: 0.2s all;

    .headOtherProducts {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        align-items: center;

        h3 {
            margin: 0 0 0 7px;
            font-size: 18px;
            color: #4e719e;
            font-weight: bold;
        }

        .headOtherProductsIconBack {
            cursor: pointer;
            font-size: 10px;
        }
    }
}

.otherProductContentOpened {
    top: 0px;
    height: 100%;
    opacity: 1;
    visibility: visible;
    transition: 0.2s all;
}

.budgetFormHead {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0;

    input {
        width: 148px;
        height: 40px;
        border: none;
        border-radius: 4px;
        background: #4079c6;
        text-transform: uppercase;
        color: #fff;
        font-size: 12px;
    }

    a {
        display: flex;
        justify-content: center;
        margin-right: 10px;
        align-items: center;
        width: 148px;
        height: 40px;
        border: none;
        border-radius: 4px;
        background: #4079c6;
        text-transform: uppercase;
        color: #fff;
        font-size: 12px;
    }
}

.formAddProduct {
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    position: fixed;
    background: rgba(0, 0, 0, 0.82);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all;

    .formAddProductOverlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .formAddProductContent {
        width: 50%;
        height: auto;
        background: #fff;
        border-radius: 8px;
        position: relative;

        > h5 {
            font-size: 22px;
            margin: 15px;
            color: rgba(0, 0, 0, 0.5);
        }

        .formAddProductContentSection {
            width: 100%;
            box-sizing: border-box;
            padding: 15px;
            background: #eee;
            border-radius: 7px;
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            .formAddProductContentSectionCatAndMarker {
                display: flex;
                flex-direction: row;

                h6 {
                    display: flex;
                    width: 200px;
                    height: 48px;
                    cursor: pointer;
                    background: #fff;
                    justify-content: center;
                    align-items: center;
                    margin: 0 10px 0 0;
                }
            }

            h6 {
                display: block;
                margin: 0 0 10px 0;
                cursor: pointer;
            }

            select {
                width: 100%;
                height: 35px;
                border: none;
                outline: none;
                border-radius: 8px;
            }

            .formSearchProduct {
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;

                > input[type='text'] {
                    width: 85%;
                    height: 35px;
                    border: 1px solid #666;
                    outline: none;
                    padding: 0 15px;
                }

                > input[type='submit'] {
                    width: 15%;
                    height: 35px;
                    border: none;
                    text-transform: uppercase;
                    font-weight: normal;
                    color: #fff;
                    font-size: 12px;
                    outline: none;
                    background: #05152c;
                    cursor: pointer;
                }
            }
        }
    }
}

.formAddProductOpened {
    opacity: 1;
    visibility: visible;

    transition: 0.2s all;
}

.addProductButton {
    width: 200px;
    height: 45px;
    margin: 0 15px 15px 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background: #7bbc13;
    color: #fff;
    font-size: 18px;
}

.listTemplates {
    width: 100%;
    display: flex;
    flex-direction: column;

    .itemTemplate {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: rgba(204, 204, 204, 0.2);
        border-radius: 5px;
        margin-bottom: 5px;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;

        transition: 0.2s all;

        &:hover {
            background: rgba(204, 204, 204, 0.3);

            transition: 0.2s all;
        }

        .rightPanelListBudget {
            display: flex;
            flex-direction: row;
            align-items: center;

            small {
                margin: 0 15px 0 0;
                padding: 5px 10px;
                background: #4079c6;
                border-radius: 5px;
                color: #fff;
            }

            span {
                position: relative;
                top: 2px;
            }
        }

        .itemTemplateLeft {
            display: flex;
            flex-direction: row;

            span:nth-child(1) {
                margin-right: 15px;
                color: rgba(0, 0, 0, 0.5);
            }
            span:nth-child(2) {
                font-size: 17px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.9);
            }
        }
    }
}

.itemGroup {
    width: 100%;
    cursor: pointer;
    margin-bottom: 5px;
    background: rgb(239, 242, 245);
    box-sizing: border-box;
    padding: 15px 10px;
    border-radius: 5px;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.1);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h4 {
        margin: 0;
    }

    .editGroup {
        margin-right: 10px;
        color: #333;
    }
}

.mainBlockGroupTemplateForm {
    input {
        width: 100%;
        height: 40px;
        margin: 0 0 5px 0;
    }
    input[type='submit'] {
        border: none;
        outline: none;
        width: 200px;
        font-weight: normal;
        font-size: 16px;
        background: #72c733;
        color: #fff;
    }
}

.listGroupsForm {
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 5px 0;

    select {
        width: 100%;
        height: 40px;
    }
}

.no_products_block {
    width: 100%;

    h5 {
        margin: 5px 11px;
        font-size: 14px;
        color: #000;
        font-weight: bold;
    }
}

.itemCategoryWithProducts {
    width: 100%;
    margin-bottom: 5px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.55s all;

    .itemCategoryWithProductsArrow {
        width: 100%;
        position: relative;

        &:after {
            content: '';
            width: 70px;
            height: 60px;
            background: url(../../../../assets/icons/arrow-180.png) center /
                contain no-repeat;
            z-index: 1;
            position: absolute;
            opacity: 0.1;
            top: -16px;
            left: -42px;
            transform: rotate(146deg);
            transition: 0.4s all;
        }

        &:hover:after {
            opacity: 0.47;
            transition: 0.4s all;
        }
    }

    .itemCategorySubCats {
        padding-left: 17px;
        visibility: hidden;
        position: relative;
        top: -15px;
        opacity: 0;
        overflow: hidden;
        height: 0;
        transition: 0.55s all;
    }

    .itemCategorySubCatsOpened {
        visibility: visible;
        top: 0;
        height: 100%;
        opacity: 1;
        transition: 0.55s all;
    }

    .itemCategoryWithProductsHeader {
        width: 100%;
        display: flex;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 15px 5px;
        background: #fff;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        transition: 0.3s all;

        &:hover {
            background: #eee;

            transition: 0.3s all;
        }

        .itemCategoryWithProductsHeaderTitle {
            display: flex;
            flex-direction: row;
            align-items: center;

            .openArrowBottom {
                transform: rotate(90deg);
                transition: 0.4s all;
            }

            .openArrowRight {
                transform: rotate(0deg);
                transition: 0.4s all;
            }

            h4 {
                margin: 0;
                margin-left: 10px;
                font-size: 15px;
                font-weight: normal;
            }

            h5 {
                margin: 10px;
            }
        }
    }
}

.subProductItems {
    width: 100%;

    > span {
        cursor: pointer;
        display: block;
        padding: 4px 10px;
        background: #bd5959;
        color: #fff;
        border-radius: 5px;
        width: fit-content;
        margin: 5px 0;
    }
}

.modalCreateCloneTemplate {
    width: 100%;
    z-index: 9999991;

    input {
        height: 45px;
        margin: 5px 0;
    }
}

.modalFormForProduct {
    input {
        height: 45px;
        font-size: 19px;
        margin-bottom: 6px;
    }

    .step {
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        position: relative;

        p {
            font-weight: normal;
            margin: 0;
            position: absolute;
            width: 20px;
            height: 20px;
            font-size: 10px;
            border-radius: 50%;
            display: flex;
            color: #fff;
            background: #23436d;
            justify-content: center;
            align-items: center;
            top: -8px;
            left: -9px;
        }

        .form {
            width: 100%;
            display: flex;
            flex-direction: row;

            b {
                width: 70px;
                background: #efefef;
                padding: 12px 5px;
                line-height: 14px;
                text-align: center;
                color: #9b9b9b;
                border-radius: 5px;
                font-weight: normal;
            }

            .formSelect {
                text-align: center;
                width: 45px;
                border-radius: 7px;
                border: 1px solid #cfcfcf;
                font-weight: normal;
                outline: none;
                font-size: 17px;
                margin: 0 10px;
            }
        }
    }
}

.selectCategoryModal {
    width: 100vw;
    height: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999999999;
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.24s all;

    .listBlock {
        width: 50%;
        height: 75vh;
        box-sizing: border-box;
        padding: 15px;
        background: #fff;
        border-radius: 10px;

        .listBlockHeader {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;

            p {
                margin: 0px;
                font-weight: bold;
            }
        }

        .listBlockContent {
            width: 100%;
            overflow-y: auto;
            height: calc(100% - 40px);

            .item {
                width: 100%;
                cursor: pointer;
                padding: 7px;
                border-radius: 5px;

                p {
                    margin: 0px;
                }
            }

            .itemChild {
                padding-left: 20px;
                background: transparent;
                transition: 0.3s all;

                &:hover {
                    background: rgba(0, 0, 0, 0.035);

                    transition: 0.3s all;
                }
            }
        }
    }
}

.selectCategoryModalOpened {
    height: 100vh;
    opacity: 1;
    visibility: visible;

    transition: 0.37s all;
}

.sectionForm {
    cursor: pointer;
    width: 100%;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    padding: 10px;
    box-sizing: border-box;
}

.sectionFormInput {
    width: 100%;
    margin: 5px 0;
}

.sectionFormMarkers {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #e9e9e9;

    .typeMarker {
        width: 100%;

        .typeMarkerSelect {
            width: 100%;

            .typeMarkerOption {
                z-index: 99999999;
            }
        }

        .checkbox {
            margin: 7px 0;
        }
    }

    .typeMarkersList {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        margin: 5px 0;
    }
}

.priceSection {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ececec;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px 0;

    .priceFormSection {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
}

.successPrice {
    color: #6ba217;
}
.warningPrice {
    animation: 1s slideColor infinite;
}

@keyframes slideColor {
    0% {
        color: #ff2200;
    }

    75% {
        color: #e9c2c2;
    }

    100% {
        color: #ff2200;
    }
}
