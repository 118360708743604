.authBlock {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: url(https://files.logovo.kz/files/2023/01/05/1672913982893.webp)
        center center / cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    .blockForm {
        width: 35vw;
        padding: 20px;
        border-radius: 15px;
        box-sizing: border-box;
        max-width: 550px;
        background-color: rgba(255, 255, 255, 0.75);

        .tooltip {
            margin: 7px 0;
        }

        .footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 15px 0 0 0;
            opacity: 0.45;
            text-transform: uppercase;
            font-size: 13px;
        }

        h1 {
            text-transform: uppercase;
            font-size: 15px;
            color: #7c7c7c;
        }
    }

    .gridcontent {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100vw;
        height: 100vh;

        .gridItem {
            width: 10%;
            height: 10vh;
            border: 1px solid #333;
            float: left;
            overflow: hidden;

            transition: 0.4s all;
            transition-delay: 1s;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
