.columnGrid {
    margin: 10px;
    padding: 15px;

    h2 {
        font-size: 16px;
        margin: 0 0 20px 0;
        width: fit-content;
    }
}

.columnGridChess {
    width: 100%;
    display: flex;
    min-width: 120px;
    flex-direction: column;
    background: #abbada;
    border-radius: 5px;
    padding: 10px;

    .columnGridFloor {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;

        > span {
            position: absolute;
            left: -33px;
            font-size: 12px;
            font-weight: bold;
            color: #102a43;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
        }

        .floorClear {
            width: 100%;
            height: 40px;
            background: #1977f3;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px 0;
            border-radius: 5px;

            /*text*/
            h6 {
                color: rgba(255, 255, 255, 0.67);
                text-align: center;
            }
        }

        .columnGridFlat {
            width: 40px;
            margin: 5px;
            height: 40px;
            border-radius: 6px;
            background: #fff;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            user-select: none;
            position: relative;

            h5 {
                display: flex;
                box-sizing: border-box;
                padding: 0px;
                font-size: 11px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0;
                justify-content: center;
                align-items: center;
            }

            > input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                z-index: 999;
                background: #1977f3;
                outline: none;
                border: none;
                border-radius: 5px;
                color: #fff;
                z-index: 10000;
            }

            .changePriceBlock {
                min-width: 320px;
                position: absolute;
                top: -15px;
                left: 100%;
                margin-left: 5px;
                background: #0a2463;
                box-sizing: border-box;
                padding: 10px 10px 20px 10px;
                border-radius: 8px;
                z-index: 1111;
                display: flex;
                flex-direction: column;

                > h4 {
                    margin-top: 20px;
                    font-weight: bold;
                    font-size: 17px;
                    color: #fff;
                }

                .changePriceBlockCloseIcon {
                    position: absolute;
                    top: 11px;
                    right: 11px;
                    color: #fff;
                }

                .changePriceBlockForm {
                    display: flex;
                    flex-direction: row;
                    width: 100%;

                    .changePriceBlockFormLeft {
                        margin-right: 15px;

                        p {
                            font-size: 18px;
                            margin: 0;
                            font-weight: normal;
                            color: #fff;
                        }
                    }

                    .changePriceBlockFormRight {
                        width: 60%;

                        > input[type='text'] {
                            width: 154px;
                            height: 30px;
                            border-radius: 5px;
                            border: 1px solid #fff;
                            background: transparent;
                            outline: none;
                            margin-bottom: 7px;
                            text-align: center;
                            color: #fff;
                        }

                        > input[type='submit'] {
                            width: 154px;
                            height: 30px;
                            border-radius: 5px;
                            background: #1977f3;
                            color: #fff;
                            outline: none;
                            border: none;
                            color: #fff;
                        }
                    }
                }
            }

            .changePriceBlockRightMargin {
                left: auto;
                right: 100%;
                margin-right: -15px;
                top: 15px;
            }

            .flatContentMenu {
                background: #f9f9f9;
                border: 1px solid #acacac;
                border-radius: 4px;
                position: absolute;
                border-radius: 2px;
                z-index: 999;
                top: 0;
                left: 100%;

                > p {
                    width: 150px;
                    display: block;
                    padding: 10px;
                    color: #333;
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0);
                    margin: 0;

                    transition: 0.2s all;

                    &:hover {
                        background: #f3f3f3;

                        transition: 0.2s all;
                    }
                }
            }

            .flatContentMenuRight {
                right: 100%;
                left: auto;
                top: calc(-100% - 10px);
            }
        }

        .flatSold {
            background: #0a2463;
            color: #fff;

            h5 {
                color: #fff;
            }
        }

        .flatSecondary {
            background: rgb(218, 197, 14);
            color: #fff;

            h5 {
                color: #fff;
            }
        }
    }
}

.popoverButtons {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;

    .popoverButton {
        margin-bottom: 5px;
    }

    .popoverContent {
        width: 100%;

        p {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            b {
                width: 230px;
                display: block;
            }

            span {
                margin: 0;
            }
        }
    }
}
