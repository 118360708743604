
.deleteFlatIcon {
	color:red;
	margin: 0 10px 0 0;
}

.editFlatPoints {
	position: relative;
	top:2px;
}

.itemFlatOfBlock {
	background:#f3f3f3;
	border:1px solid rgba(0,0,0,0.1);
	box-sizing: border-box;
	padding: 10px;
	transition:.2s all;

	&:hover {
		background:#fff;
		transition:.2s all;
	}

	h6 {
		margin: 0px;
		font-size:18px;
		padding: 7px 22px;
		bborder-radius:5px;
		background:#fff;
	}
}

.headPlanImage {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.blockPlanImage {
	width: 100%;
	position: relative;

	.optionsWidth {
		position: absolute;
		right: 0;
		display: flex;
		flex-direction: row;
		z-index: 9999;

		span {
			margin-left: 5px;
			cursor: pointer;
			background: #ebebeb;
			border: 1px dashed #919191;
			padding: 5px 13px;
			color: #333;

			transition: .2s all;

			&:hover {
				background: #4079c6;
    			color: #fff;

				transition: .2s all;
			}
		}

		span.activeOption {
			background: #4079c6;
    		color: #fff;
		}
	}
}