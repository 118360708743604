.listUsers {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;

    .userItem {
        width: 100%;
        cursor: pointer;

        .Dropdown {
            position: absolute;
            top: 15px;
            left: 5px;

            .userItemSettingIcon {
                font-size: 22px;
            }
        }

        .useravatarBlock {
            background: #f1f1f1;

            .userAvatar {
                width: 70px;
                height: 70px;
                background-color: red;
                border-radius: 50%;
            }
        }

        .userInfo {
            width: 100%;
            background: #fff;

            h5 {
                font-size: 12px;
            }

            .email {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 7px;

                h5 {
                    margin: 0;
                }

                span {
                    margin: 0;
                    width: 20px;
                    padding: 0;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .dateReg {
                width: 100%;
                margin-bottom: 6px;
            }

            .blockUserStatus {
                margin: 2px 0;
                font-size: 11px;
                text-transform: none;
                padding: 2px 15px;
            }
        }
    }
}

.optionStyle {
    display: flex;
    flex-direction: row;
}

.filter {
    width: 100%;
    position: sticky;
    top: 0px;
    margin: 15px 0;
    padding: 0;
    z-index: 999;
    border-radius: 5px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 15px;
    justify-content: space-around;

    .filterSearch {
        width: 100%;
    }

    .filterSort {
        width: 100%;
        z-index: 10000;

        .select {
            width: 100%;
            min-width: 450px;
            height: 45px;
        }
    }
}

.buttonMore {
    width: 100%;
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.blockInfoUser {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .sectionTool {
        width: 100%;
        padding: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        background-color: transparent;

        transition: .2s all;

        &:hover {
            background-color: rgba(0,0,0,0.05);
            cursor: pointer;

            transition: .2s all;
        }

        h5 {
            margin: 0;
            display: block;
            width: 190px;
        }

        .more {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}

.itemForm {
    width: 100%;
    margin: 0 0 7px 0;
}
