.layer {
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.057);
    min-height: 100vh;
}

.content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px 10px 10px;
    overflow-x: hidden;
}
